@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #1468a0; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1779ba;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #14679e;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #1779ba;
    color: #fefefe; }
    .button.primary:hover, .button.primary:focus {
      background-color: #126195;
      color: #fefefe; }
  .button.secondary {
    background-color: #767676;
    color: #fefefe; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #fefefe; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #1779ba;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #1779ba;
        color: #fefefe; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #fefefe; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #fefefe; }
  .button.hollow {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #1779ba;
        color: #1779ba; }
    .button.hollow.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #1779ba;
        color: #1779ba; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.accordion-menu li {
  width: 100%; }

.accordion-menu a {
  padding: 0.7rem 1rem; }

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem; }

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem; }

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative; }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem; }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.is-accordion-submenu-parent {
  position: relative; }

.has-submenu-toggle > a {
  margin-right: 40px; }

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px; }
  .submenu-toggle::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    top: 0;
    bottom: 0;
    margin: auto; }

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%; }

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #1779ba;
  color: #fefefe; }
  .badge.primary {
    background: #1779ba;
    color: #fefefe; }
  .badge.secondary {
    background: #767676;
    color: #fefefe; }
  .badge.success {
    background: #3adb76;
    color: #0a0a0a; }
  .badge.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .badge.alert {
    background: #cc4b37;
    color: #fefefe; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #0a0a0a;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #1779ba; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #1779ba;
    color: #fefefe; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #126195;
      color: #fefefe; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #fefefe; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #fefefe; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d7ecfa;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #0a0a0a; }
  .callout.success {
    background-color: #e1faea;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  flex: 1 0 auto;
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu .active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fefefe; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #1779ba; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #fefefe; }
  .label.primary {
    background: #1779ba;
    color: #fefefe; }
  .label.secondary {
    background: #767676;
    color: #fefefe; }
  .label.success {
    background: #3adb76;
    color: #0a0a0a; }
  .label.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .label.alert {
    background: #cc4b37;
    color: #fefefe; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px); }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-250px); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(250px); }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px); }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(250px); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-250px); }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fefefe; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(10, 10, 10, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #8a8a8a; }
    .orbit-bullets button.is-active {
      background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #1779ba;
    color: #fefefe;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #1779ba; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #1779ba; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #1779ba;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #14679e; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fefefe;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #1779ba; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 1px solid #f1f1f1;
    background-color: #fefefe; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  table thead {
    background: #f8f8f8;
    color: #0a0a0a; }
  table tfoot {
    background: #f1f1f1;
    color: #0a0a0a; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fefefe; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #1779ba; }
  .tabs.primary > li > a {
    color: #fefefe; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #1673b1; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #1779ba; }
    .tabs-title > a:hover {
      background: #fefefe;
      color: #1468a0; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #1779ba; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel.is-active {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(23, 121, 186, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe; }
  .tooltip::before {
    position: absolute; }
  .tooltip.bottom::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    bottom: 100%; }
  .tooltip.bottom.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.top.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    left: 100%; }
  .tooltip.left.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    right: 100%;
    left: auto; }
  .tooltip.right.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.align-top::before {
    bottom: auto;
    top: 10%; }
  .tooltip.align-bottom::before {
    bottom: 10%;
    top: auto; }
  .tooltip.align-left::before {
    left: 10%;
    right: auto; }
  .tooltip.align-right::before {
    left: auto;
    right: 10%; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.clearfix::after {
  clear: both; }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./assets/img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
  background: #f7f5f2;
  /* top: 85%; */
  left: 133px;
  text-align: right;
  width: auto; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 14px;
    width: 14px;
    margin: 0 0 0 10px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 14px;
      width: 14px;
      outline: none;
      color: transparent;
      padding: 0;
      cursor: pointer;
      background: #f7f5f2;
      border: 3px solid #242f3a; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        background: #242f3a; }
    .slick-dots li.slick-active button {
      background: #242f3a; }

/* Simple border mixin */
/* Simple fullborder mixin */
/* Modular Scale*/
@font-face {
  font-family: 'u8bold';
  src: url("../fonts/u8-bold-webfont.woff2") format("woff2"), url("../fonts/u8-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

.header-font, .fonts-loaded h1, .wf-active h1, .fonts-loaded h2, .wf-active h2, .fonts-loaded h3, .wf-active h3, .fonts-loaded h4, .wf-active h4, .fonts-loaded h5, .wf-active h5, .fonts-loaded h6, .wf-active h6, .fonts-loaded .button, .fonts-loaded .centred-quote p, .fonts-loaded .text-image h3, .wf-active .button, .wf-active .centred-quote p, .wf-active .text-image h3, .fonts-loaded .hero-content h3, .wf-active .hero-content h3, .fonts-loaded ul li, .fonts-loaded ul strong.place, .wf-active ul li, .wf-active ul strong.place, .fonts-loaded form, .wf-active form, .fonts-loaded form label, .fonts-loaded form input, .fonts-loaded form textarea, .wf-active form label, .wf-active form input, .wf-active form textarea {
  font-family: "u8bold", Arial, sans-serif !important;
  font-style: normal;
  font-weight: normal; }

.subheader-font, .text-image-list h3, .text-image-list p, .fonts-loaded h2, .fonts-loaded h3, .fonts-loaded .centred-image-two-cols h4, .fonts-loaded .breakout-text-list p, .fonts-loaded .breakout-text-button .large-6:last-of-type p, .fonts-loaded .feature .text-image h3, .fonts-loaded .housing-choice .sectiontitle p, .fonts-loaded .housing-choice .column h5, .fonts-loaded .housing-choice .columns h5, .wf-active h2, .wf-active h3, .wf-active .centred-image-two-cols h4, .wf-active .breakout-text-list p, .wf-active .breakout-text-button .large-6:last-of-type p, .wf-active .feature .text-image h3, .wf-active .housing-choice .sectiontitle p, .wf-active .housing-choice .column h5, .wf-active .housing-choice .columns h5, .fonts-loaded .hero-content h4, .wf-active .hero-content h4 {
  font-family: "filosofia", Arial, sans-serif !important;
  font-style: normal;
  font-weight: 700; }

.body-font-bold, .fonts-loaded nav a, .wf-active nav a {
  font-family: "filson-pro", Arial, sans-serif;
  font-style: normal;
  font-weight: 700; }

.body-font-medium, .fonts-loaded p strong, .fonts-loaded p a, .wf-active p strong, .wf-active p a, .fonts-loaded h1 + p, .wf-active h1 + p, .fonts-loaded h2 + p, .wf-active h2 + p, .fonts-loaded h3 + p, .wf-active h3 + p, .fonts-loaded h4 + p, .wf-active h4 + p, .fonts-loaded h5 + p, .wf-active h5 + p, .fonts-loaded h6 + p, .wf-active h6 + p, .fonts-loaded .centred-image-text, .fonts-loaded .centred-image-two-cols, .fonts-loaded .text-only, .fonts-loaded .location, .fonts-loaded .downloads, .fonts-loaded .signup, .fonts-loaded .three-col, .fonts-loaded .connected-section, .fonts-loaded .text-image-list, .wf-active .centred-image-text, .wf-active .centred-image-two-cols, .wf-active .text-only, .wf-active .location, .wf-active .downloads, .wf-active .signup, .wf-active .three-col, .wf-active .connected-section, .wf-active .text-image-list, .fonts-loaded .centred-image-text p, .fonts-loaded .centred-image-two-cols p, .fonts-loaded .text-only p, .fonts-loaded .location p, .fonts-loaded .downloads p, .fonts-loaded .signup p, .fonts-loaded .three-col p, .fonts-loaded .connected-section p, .fonts-loaded .text-image-list p, .wf-active .centred-image-text p, .wf-active .centred-image-two-cols p, .wf-active .text-only p, .wf-active .location p, .wf-active .downloads p, .wf-active .signup p, .wf-active .three-col p, .wf-active .connected-section p, .wf-active .text-image-list p, .fonts-loaded #housetypes h4, .wf-active #housetypes h4, .fonts-loaded .slick-slide figcaption p, .wf-active .slick-slide figcaption p, .fonts-loaded .text-image p, .wf-active .text-image p {
  font-family: "filson-pro", Arial, sans-serif;
  font-style: normal;
  font-weight: 400; }

.body-font-book, .fonts-loaded .breakout-text-list ul li, .wf-active .breakout-text-list ul li, .fonts-loaded .breakout-text-list ul li p, .wf-active .breakout-text-list ul li p, .fonts-loaded .accordion, .fonts-loaded .standard-text, .wf-active .accordion, .wf-active .standard-text, .fonts-loaded .accordion li, .fonts-loaded .standard-text li, .wf-active .accordion li, .wf-active .standard-text li, .fonts-loaded .accordion li p, .fonts-loaded .standard-text li p, .wf-active .accordion li p, .wf-active .standard-text li p {
  font-family: "filson-pro", Arial, sans-serif !important;
  font-style: normal;
  font-weight: 500; }

.body-font-light, .fonts-loaded body, .wf-active body {
  font-family: "filson-pro", Arial, sans-serif;
  font-style: normal;
  font-weight: 400; }

.self-clear:after, nav ul:after {
  content: "";
  display: table;
  clear: both; }

.row {
  max-width: 76.875rem; }

.row .row {
  max-width: 100%; }

.row.fullwidth {
  max-width: 100%; }

section {
  position: relative;
  display: block; }
  @media screen and (max-width: 63.9375em) {
    section {
      max-width: 99% !important;
      margin-left: auto !important;
      margin-right: auto !important; } }

*:focus {
  outline: 0 !important; }

body {
  font-size: 1em;
  background: #f7f5f2;
  color: #47515d;
  font-family: sans-serif;
  opacity: 1;
  transition: transform .3s ease-in-out, opacity .3s ease-in-out;
  -webkit-transition: -webkit-transform .3s ease-in-out, opacity .3s ease-in-out; }

p {
  font-size: 1rem;
  margin-bottom: 1.25rem;
  line-height: 1.4; }
  p::selection {
    background: #e2e5e9;
    color: #47515d; }
  @media print, screen and (min-width: 40em) {
    p {
      margin-bottom: 1.875rem; } }
  p strong {
    font-weight: 700 !important; }
    p strong::selection {
      background: #e2e5e9;
      color: #47515d; }
  p a {
    color: #47515d;
    padding-bottom: 0.125rem;
    border-bottom: 0.125rem solid #f7b082; }
    p a::selection {
      background: #e2e5e9;
      color: #47515d; }
    p a:hover {
      color: #f7b082; }
      p a:hover::selection {
        background: #fef3ec;
        color: #f7b082; }
    p a:visited {
      color: #f7b082; }
      p a:visited::selection {
        background: #fef3ec;
        color: #f7b082; }

span::selection, img::selection {
  background: #e2e5e9;
  color: #47515d; }

h1 {
  font-size: 1.65349em;
  margin-bottom: 0.625rem;
  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.09375rem !important; }
  @media (min-width: 20em) and (max-width: 75em) {
    h1 {
      font-size: calc( 1.65349em + 0.83468 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    h1 {
      font-size: 2.48817em; } }
  h1::selection {
    background: #e2e5e9;
    color: #47515d; }
  @media print, screen and (min-width: 40em) {
    h1 {
      margin-bottom: 0.9375rem; } }

h2 {
  font-size: 1.45815em;
  margin-bottom: 0.625rem;
  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.09375rem !important; }
  @media (min-width: 20em) and (max-width: 75em) {
    h2 {
      font-size: calc( 1.45815em + 0.52297 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    h2 {
      font-size: 1.98112em; } }
  h2::selection {
    background: #e2e5e9;
    color: #47515d; }
  @media print, screen and (min-width: 40em) {
    h2 {
      margin-bottom: 0.9375rem; } }

h3 {
  font-size: 1em;
  margin-bottom: 0.625rem;
  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.09375rem !important; }
  @media (min-width: 20em) and (max-width: 75em) {
    h3 {
      font-size: calc( 1em + 0 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    h3 {
      font-size: 1em; } }
  h3::selection {
    background: #e2e5e9;
    color: #47515d; }
  @media print, screen and (min-width: 40em) {
    h3 {
      margin-bottom: 0.9375rem; } }

h4 {
  font-size: 1em;
  margin-bottom: 0.625rem;
  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.09375rem !important; }
  @media (min-width: 20em) and (max-width: 75em) {
    h4 {
      font-size: calc( 1em + 0 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    h4 {
      font-size: 1em; } }
  h4::selection {
    background: #e2e5e9;
    color: #47515d; }
  @media print, screen and (min-width: 40em) {
    h4 {
      margin-bottom: 0.9375rem; } }

h5 {
  font-size: 1em;
  margin-bottom: 0.625rem;
  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.09375rem !important; }
  @media (min-width: 20em) and (max-width: 75em) {
    h5 {
      font-size: calc( 1em + 0 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    h5 {
      font-size: 1em; } }
  h5::selection {
    background: #e2e5e9;
    color: #47515d; }
  @media print, screen and (min-width: 40em) {
    h5 {
      margin-bottom: 0.9375rem; } }

h6 {
  font-size: 1em;
  margin-bottom: 0.625rem;
  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.09375rem !important; }
  @media (min-width: 20em) and (max-width: 75em) {
    h6 {
      font-size: calc( 1em + 0 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    h6 {
      font-size: 1em; } }
  h6::selection {
    background: #e2e5e9;
    color: #47515d; }
  @media print, screen and (min-width: 40em) {
    h6 {
      margin-bottom: 0.9375rem; } }

h1 {
  font-size: 1.65349em;
  margin-bottom: 0.625rem;
  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.09375rem !important; }
  @media (min-width: 20em) and (max-width: 75em) {
    h1 {
      font-size: calc( 1.65349em + 0.83468 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    h1 {
      font-size: 2.48817em; } }
  h1::selection {
    background: #e2e5e9;
    color: #47515d; }
  @media print, screen and (min-width: 40em) {
    h1 {
      margin-bottom: 0.9375rem; } }

h2 {
  font-size: 1.45815em;
  margin-bottom: 0.625rem;
  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.09375rem !important; }
  @media (min-width: 20em) and (max-width: 75em) {
    h2 {
      font-size: calc( 1.45815em + 0.52297 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    h2 {
      font-size: 1.98112em; } }
  h2::selection {
    background: #e2e5e9;
    color: #47515d; }
  @media print, screen and (min-width: 40em) {
    h2 {
      margin-bottom: 0.9375rem; } }

h3 {
  font-size: 1em;
  margin-bottom: 0.625rem;
  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.09375rem !important; }
  @media (min-width: 20em) and (max-width: 75em) {
    h3 {
      font-size: calc( 1em + 0 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    h3 {
      font-size: 1em; } }
  h3::selection {
    background: #e2e5e9;
    color: #47515d; }
  @media print, screen and (min-width: 40em) {
    h3 {
      margin-bottom: 0.9375rem; } }

h4 {
  font-size: 1em;
  margin-bottom: 0.625rem;
  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.09375rem !important; }
  @media (min-width: 20em) and (max-width: 75em) {
    h4 {
      font-size: calc( 1em + 0 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    h4 {
      font-size: 1em; } }
  h4::selection {
    background: #e2e5e9;
    color: #47515d; }
  @media print, screen and (min-width: 40em) {
    h4 {
      margin-bottom: 0.9375rem; } }

h5 {
  font-size: 1em;
  margin-bottom: 0.625rem;
  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.09375rem !important; }
  @media (min-width: 20em) and (max-width: 75em) {
    h5 {
      font-size: calc( 1em + 0 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    h5 {
      font-size: 1em; } }
  h5::selection {
    background: #e2e5e9;
    color: #47515d; }
  @media print, screen and (min-width: 40em) {
    h5 {
      margin-bottom: 0.9375rem; } }

h6 {
  font-size: 1em;
  margin-bottom: 0.625rem;
  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.09375rem !important; }
  @media (min-width: 20em) and (max-width: 75em) {
    h6 {
      font-size: calc( 1em + 0 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    h6 {
      font-size: 1em; } }
  h6::selection {
    background: #e2e5e9;
    color: #47515d; }
  @media print, screen and (min-width: 40em) {
    h6 {
      margin-bottom: 0.9375rem; } }

.sectiontitle h2 {
  text-transform: capitalize;
  font-size: 1.65349em;
  text-align: center;
  position: relative;
  line-height: 3.75rem;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  min-height: 3.75rem;
  padding-bottom: 1.875rem;
  max-width: 56.25rem;
  letter-spacing: 0.125rem; }
  @media (min-width: 20em) and (max-width: 75em) {
    .sectiontitle h2 {
      font-size: calc( 1.65349em + 0.83468 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    .sectiontitle h2 {
      font-size: 2.48817em; } }
  @media screen and (max-width: 63.9375em) {
    .sectiontitle h2 {
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem; } }
  @media screen and (max-width: 39.9375em) {
    .sectiontitle h2 {
      line-height: 1.3; } }
  @media screen and (max-width: 43.75em) {
    .sectiontitle h2 {
      max-width: 85%; } }
  .sectiontitle h2 span {
    display: inline-block;
    position: relative; }
    @media screen and (max-width: 43.75em) {
      .sectiontitle h2 span {
        max-width: 90%; } }
    .sectiontitle h2 span:before, .sectiontitle h2 span:after {
      display: block;
      content: '';
      width: 0.875rem;
      height: 0.125rem;
      background: #47515d;
      position: absolute;
      left: -1.875rem;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      z-index: 501; }
    .sectiontitle h2 span:after {
      left: auto;
      right: -1.875rem; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    text-transform: none;
    margin: 0 0 0.9375rem 0;
    list-style-type: none;
    position: relative;
    padding-left: 1.25rem;
    line-height: 1.4;
    display: block; }
    ul li::selection {
      background: #e2e5e9;
      color: #47515d; }
    ul li:before {
      position: absolute;
      left: 0;
      top: 0.5625rem;
      display: block;
      width: 0.3125rem;
      height: 0.3125rem;
      background: #f7b082;
      content: '';
      border-radius: 50%; }
    ul li ul {
      margin-top: 0.625rem; }
      ul li ul li {
        font-size: 0.8125rem; }
        ul li ul li:before {
          position: absolute;
          left: 0;
          top: 0.5625rem;
          display: block;
          width: 0.3125rem;
          height: 0.3125rem;
          background: #47515d;
          content: '';
          border-radius: 50%; }

.slick-dots li:before {
  display: none; }

.columns ul li {
  letter-spacing: 0.001rem !important; }

.button {
  font-size: 0.88186em;
  color: #47515d;
  background: #f7b082;
  text-transform: uppercase;
  padding: 1.25rem 1.875rem;
  letter-spacing: 0.09375rem; }
  @media (min-width: 20em) and (max-width: 75em) {
    .button {
      font-size: calc( 0.88186em + -0.08565 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    .button {
      font-size: 0.79621em; } }
  .button:hover {
    background: #47515d;
    color: #f7f5f2; }

table {
  margin-bottom: 1.25rem; }
  @media print, screen and (min-width: 40em) {
    table {
      margin-bottom: 1.875rem; } }
  table thead, table tbody, table tfoot {
    border: 1px solid rgba(71, 81, 93, 0.7);
    background-color: #f7f5f2; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: rgba(71, 81, 93, 0.05); }

.mobile-nav-wrap {
  position: relative;
  width: 100%;
  display: block;
  height: 2.5rem;
  display: none; }
  @media screen and (max-width: 59.375em) {
    .mobile-nav-wrap {
      display: block !important;
      border-bottom: 0.125rem solid #f7b082; } }
  .mobile-nav-wrap .register {
    position: absolute;
    display: block;
    width: auto;
    height: auto;
    bottom: 0;
    right: 0;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.625rem;
    width: 45%;
    max-width: 6.25rem;
    color: #47515d !important;
    background: #f7b082 !important;
    line-height: 28px; }
    .mobile-nav-wrap .register:hover {
      color: #f7f5f2;
      background: #47515d; }

.mobile-nav {
  position: absolute;
  display: block;
  width: 1.3125rem;
  height: 0.75rem;
  top: 0.8125rem;
  left: 0; }

a#open-nav {
  width: 1.3125rem;
  height: 0.75rem;
  margin: 0 auto;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  display: block; }

a#open-nav span {
  display: block;
  position: absolute;
  height: 0.125rem;
  width: 100%;
  background: #47515d;
  border-radius: 0.125rem;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .20s ease-in-out; }

a#open-nav span:nth-child(1) {
  top: 0; }

a#open-nav span:nth-child(2), a#open-nav span:nth-child(3) {
  top: 50%; }

a#open-nav span:nth-child(4) {
  top: 100%; }

html.nav-opened a#open-nav span {
  background: #f7b082; }

html.nav-opened a#open-nav span:nth-child(1) {
  top: 50%;
  width: 0%;
  left: 50%; }

html.nav-opened a#open-nav span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

html.nav-opened a#open-nav span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

html.nav-opened a#open-nav span:nth-child(4) {
  top: 50%;
  width: 0%;
  left: 50%; }

nav {
  position: relative;
  width: 100%;
  display: block;
  background: #f7f5f2;
  margin-top: 1.875rem;
  padding: 1rem; }
  @media screen and (max-width: 59.375em) {
    nav {
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 0; } }
  nav ul {
    display: block;
    max-width: 75rem;
    margin: 0 auto;
    padding: 0;
    font-family: "filson-pro";
    border-bottom: 0.125rem solid #f7b082; }
    @media screen and (max-width: 59.375em) {
      nav ul {
        padding: 0.9375rem 0;
        display: none; } }
    nav ul li {
      display: block;
      margin-bottom: -0.125rem !important;
      padding: 0 !important;
      float: left;
      clear: none;
      width: auto;
      margin-right: 5%; }
      @media screen and (max-width: 65em) {
        nav ul li {
          margin-right: 4%; } }
      @media screen and (max-width: 60.625em) {
        nav ul li {
          margin-right: 3%; } }
      nav ul li:before {
        display: none; }
      @media screen and (max-width: 59.375em) {
        nav ul li {
          float: none;
          display: block;
          width: 100%;
          margin-right: 0; } }
      nav ul li a {
        font-size: 0.6875rem;
        color: #47515d;
        text-transform: uppercase;
        display: block;
        width: 100%;
        height: 2.0625rem;
        line-height: 2.0625rem;
        letter-spacing: 0.075rem; }
        nav ul li a::selection {
          background: #e2e5e9;
          color: #47515d; }
      nav ul li a.active, nav ul li a:hover, nav ul li a:focus {
        color: #f7b082; }
        nav ul li a.active::selection, nav ul li a:hover::selection, nav ul li a:focus::selection {
          background: #fef3ec;
          color: #f7b082; }
    nav ul .additional {
      font-size: 0;
      max-width: 13.75rem;
      width: 100%;
      text-align: right;
      float: right;
      clear: none;
      margin-right: 0; }
      @media screen and (max-width: 59.375em) {
        nav ul .additional {
          display: none; } }
      nav ul .additional a {
        display: inline-block;
        text-align: center;
        font-size: 0.6875rem;
        margin-left: 4%;
        width: 48%; }
        @media screen and (min-width: 75em) {
          nav ul .additional a {
            max-width: 6.875rem; } }
      nav ul .additional a.active, nav ul .additional a:hover {
        color: #f7f5f2;
        background: #47515d; }
    nav ul .register {
      background: #f7b082; }
    nav ul .brochure {
      background: #47515d;
      color: #fefefe;
      margin-left: 0 !important; }

nav.stuck {
  margin-top: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999; }
  nav.stuck h1#contracted {
    display: block !important; }

h1.logo {
  line-height: 1; }
  h1.logo span {
    background: url("../img/marmalade-lane.svg") no-repeat center;
    background-size: 70%;
    width: 100%;
    height: 100%;
    text-indent: -99999rem;
    display: block;
    margin: 0; }

h1.logo#expanded {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%; }
  @media screen and (max-width: 59.375em) {
    h1.logo#expanded {
      display: none !important; } }

h1.logo#contracted {
  display: none !important;
  max-width: 75rem;
  margin: 0.625rem auto 0 auto; }
  @media screen and (max-width: 59.375em) {
    h1.logo#contracted {
      display: block !important;
      line-height: 2;
      margin: 0.625rem auto 0.625rem auto; } }
  h1.logo#contracted span {
    background: url("../img/marmalade-lane-title.svg") no-repeat center left;
    background-size: 20%;
    height: auto; }
    @media screen and (max-width: 59.375em) {
      h1.logo#contracted span {
        display: block !important;
        background: url("../img/marmalade-lane-title-full.svg") no-repeat center center;
        background-size: 215px 50px; } }

.standard h1.logo#contracted {
  display: block !important; }

.logo.register {
  line-height: 1;
  background-size: contain;
  background: #f7b082 url("../img/marmalade-lane.svg") no-repeat center;
  background-size: 70%;
  width: 100%;
  height: 100%;
  text-indent: -99999rem;
  display: block;
  margin: 0;
  padding: 0;
  text-indent: -99999rem;
  display: block;
  margin: 0 0 2.5rem 0;
  font-size: 3.10029em; }
  @media (min-width: 20em) and (max-width: 75em) {
    .logo.register {
      font-size: calc( 3.10029em + 4.67524 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    .logo.register {
      font-size: 7.77553em; } }
  @media screen and (max-width: 39.9375em) {
    .logo.register {
      background: #f7b082 url("../img/marmalade-lane-title-full.svg") no-repeat center 78%;
      margin: 0 0 1.5625rem 0;
      max-width: 100%;
      height: 9.375rem;
      background-size: 40%; } }

header {
  position: relative;
  background: url("../img/hero.jpg") top center no-repeat;
  background-size: cover;
  height: 100vh;
  margin-top: 0.625rem;
  display: none; }
  header .large-12 {
    max-width: 76.875rem;
    margin: 0 auto;
    position: relative;
    z-index: 500;
    float: none !important; }
  header .branding {
    width: 100%;
    height: 100vh;
    max-width: 25.9375rem;
    max-height: 21.25rem;
    margin: 0;
    padding: 0;
    background: #f7b082;
    display: block; }
    @media screen and (max-width: 59.375em) {
      header .branding {
        visibility: hidden; } }

.homepage header {
  display: block; }

.hero-content {
  margin-top: 2.5rem; }
  @media screen and (min-width: 64em) {
    .hero-content {
      margin-top: 5rem; } }
  @media screen and (min-width: 64em) {
    .hero-content .large-7 {
      border-right: 0.125rem solid #f7b082;
      padding-right: 0; } }
  .hero-content h3 {
    font-size: 1.45815em;
    letter-spacing: 0.1375rem !important; }
    @media (min-width: 20em) and (max-width: 75em) {
      .hero-content h3 {
        font-size: calc( 1.45815em + 0.52297 * ( ( 100vw - 20em) / 55 )); } }
    @media (min-width: 75em) {
      .hero-content h3 {
        font-size: 1.98112em; } }
    @media screen and (min-width: 64em) {
      .hero-content h3 {
        padding-right: 33%; } }
  .hero-content p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.6; }
    @media screen and (min-width: 64em) {
      .hero-content p {
        padding-right: 25%; } }
  .hero-content p.subheader-font, .hero-content .text-image-list p, .text-image-list .hero-content p, .hero-content .fonts-loaded .breakout-text-list p, .fonts-loaded .breakout-text-list .hero-content p, .hero-content .fonts-loaded .breakout-text-button .large-6:last-of-type p, .fonts-loaded .breakout-text-button .large-6:last-of-type .hero-content p, .hero-content .fonts-loaded .housing-choice .sectiontitle p, .fonts-loaded .housing-choice .sectiontitle .hero-content p, .hero-content .wf-active .breakout-text-list p, .wf-active .breakout-text-list .hero-content p, .hero-content .wf-active .breakout-text-button .large-6:last-of-type p, .wf-active .breakout-text-button .large-6:last-of-type .hero-content p, .hero-content .wf-active .housing-choice .sectiontitle p, .wf-active .housing-choice .sectiontitle .hero-content p {
    font-size: 1.375rem;
    padding-bottom: 1.875rem;
    line-height: 1.4;
    border-bottom: 0.125rem solid #f7b082; }
  .hero-content h4 {
    font-size: 1.625rem;
    padding-right: 1.875rem;
    text-transform: none;
    font-weight: 700 !important;
    letter-spacing: 0.001rem !important; }
  .hero-content .large-5 .awards {
    position: relative;
    display: block;
    width: 100%;
    height: 100%; }
    @media screen and (max-width: 63.9375em) {
      .hero-content .large-5 .awards {
        border-top: 0.125rem solid #f7b082;
        margin-top: 1.875rem; } }
  .hero-content .large-5 h4 {
    background: url("../img/orange.svg") center left no-repeat;
    background-size: 30%;
    text-align: left;
    width: 100%;
    max-width: 28.125rem;
    padding: 1.875rem 0 1.875rem 10rem;
    margin: 0;
    display: block;
    font-size: 1.0625rem;
    font-weight: 700 !important;
    letter-spacing: 0.028rem !important;
    margin: 1.875rem auto 0 auto; }
    @media screen and (min-width: 64em) {
      .hero-content .large-5 h4 {
        background: url("../img/orange.svg") 40% 0 no-repeat;
        background-size: 33%;
        max-width: 21.875rem;
        padding: 6.25rem 0 0 0;
        font-size: 1.5625rem;
        margin: 0;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%); } }

#housetypes {
  margin: 3.75rem auto !important;
  padding: 0;
  text-align: center;
  max-width: 75rem !important; }
  @media screen and (max-width: 75em) {
    #housetypes {
      max-width: 98% !important; } }
  @media screen and (min-width: 64em) {
    #housetypes .column-block {
      margin-bottom: 0;
      padding: 0;
      width: 25%; } }
  #housetypes .column-block h4 {
    line-height: 1;
    padding: 0;
    font-size: 1.28588em; }
    @media (min-width: 20em) and (max-width: 75em) {
      #housetypes .column-block h4 {
        font-size: calc( 1.28588em + 0.29151 * ( ( 100vw - 20em) / 55 )); } }
    @media (min-width: 75em) {
      #housetypes .column-block h4 {
        font-size: 1.57739em; } }
    @media screen and (max-width: 75em) {
      #housetypes .column-block h4 {
        font-size: 1.13397em; } }
  @media screen and (max-width: 75em) and (min-width: 20em) and (max-width: 75em) {
    #housetypes .column-block h4 {
      font-size: calc( 1.13397em + 0.12198 * ( ( 100vw - 20em) / 55 )); } }
  @media screen and (max-width: 75em) and (min-width: 75em) {
    #housetypes .column-block h4 {
      font-size: 1.25594em; } }
  @media screen and (min-width: 64em) {
    #housetypes .first {
      text-align: left; } }
  #housetypes .first h4 {
    color: #f7b082; }
  @media screen and (min-width: 64em) {
    #housetypes .second {
      width: 50%; } }
  #housetypes .second h4 {
    color: #a5a5a5; }
  @media screen and (min-width: 64em) {
    #housetypes .third {
      text-align: right; } }
  #housetypes .third h4 {
    color: #f7b082; }

.gallery .slick-slide {
  margin: 0 0.625rem; }
  @media screen and (min-width: 64em) {
    .gallery .slick-slide {
      margin: 0 2.1875rem; } }
  .gallery .slick-slide figcaption {
    margin: 1.25rem 0 0 0;
    width: 100%;
    max-width: 100%;
    display: block;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    -webkit-transition: opacity .2s ease-in-out; }
    @media screen and (min-width: 40em) {
      .gallery .slick-slide figcaption {
        max-width: 75%; } }
    @media screen and (min-width: 64em) {
      .gallery .slick-slide figcaption {
        max-width: 40.625rem; } }
    .gallery .slick-slide figcaption h5 {
      font-size: 1em; }
      @media (min-width: 20em) and (max-width: 75em) {
        .gallery .slick-slide figcaption h5 {
          font-size: calc( 1em + 0 * ( ( 100vw - 20em) / 55 )); } }
      @media (min-width: 75em) {
        .gallery .slick-slide figcaption h5 {
          font-size: 1em; } }
    .gallery .slick-slide figcaption p {
      font-size: 1em; }
      @media (min-width: 20em) and (max-width: 75em) {
        .gallery .slick-slide figcaption p {
          font-size: calc( 1em + 0 * ( ( 100vw - 20em) / 55 )); } }
      @media (min-width: 75em) {
        .gallery .slick-slide figcaption p {
          font-size: 1em; } }

.gallery .slick-current-first figcaption {
  opacity: 1; }

.gallery .slick-current-stopped figcaption {
  opacity: 1; }

.gallery .slick-dots {
  background: transparent; }
  @media screen and (max-width: 63.9375em) {
    .gallery .slick-dots {
      right: 1.5625rem;
      bottom: 5.625rem; } }

.text-image-list {
  padding: 0 0 0 0;
  /*
    &:after{
      @include border(bottom, toRem(2px), solid, $borderColour);
      display: block;
      max-width: toRem(1200px);
      content: '';
      width:100%;
      height:auto;
      margin: toRem(50px) auto;
    }
    */ }
  .text-image-list .row {
    clear: both;
    margin: 0;
    padding: 2.5rem 0 0 0; }
  .text-image-list h3 {
    width: 100%;
    font-size: 1.375rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400 !important; }
    @media screen and (min-width: 75em) {
      .text-image-list h3 {
        max-width: 80%; } }
  .text-image-list p {
    width: 100%;
    font-size: 1.375rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400 !important; }
    @media screen and (min-width: 75em) {
      .text-image-list p {
        max-width: 80%; } }
  .text-image-list ul {
    margin-top: 1.25rem; }
    @media print, screen and (min-width: 40em) {
      .text-image-list ul {
        margin-top: 1.875rem; } }
    @media screen and (min-width: 64em) {
      .text-image-list ul {
        margin-left: 1.25rem;
        margin-top: 0; } }
  @media screen and (min-width: 64em) and (min-width: 40em) {
    .text-image-list ul {
      margin-left: 1.875rem; } }
    @media screen and (max-width: 63.9375em) {
      .text-image-list ul {
        margin-top: 0;
        margin-left: 1%; } }
    @media screen and (max-width: 37.5em) {
      .text-image-list ul {
        margin-top: 1.25rem; } }
  @media screen and (max-width: 37.5em) and (min-width: 40em) {
    .text-image-list ul {
      margin-top: 1.875rem; } }
    .text-image-list ul li {
      font-size: 0.875rem; }
  @media screen and (max-width: 63.9375em) {
    .text-image-list figure {
      max-width: 98%;
      margin: 0 auto 1.875rem auto; } }
  .text-image-list img {
    width: 100%;
    max-width: 43.75rem;
    margin: 0 auto;
    display: block; }
    @media screen and (max-width: 63.9375em) {
      .text-image-list img {
        max-width: 100%; } }
  .text-image-list .extra {
    display: block;
    width: 14.0625rem;
    height: 10.9375rem;
    text-align: center;
    position: relative;
    margin-left: auto;
    margin-right: auto; }
    @media screen and (min-width: 40em) {
      .text-image-list .extra {
        float: right;
        clear: none;
        margin-left: 0;
        margin-right: 0; } }
    .text-image-list .extra h5 {
      font-size: 1em;
      z-index: 500;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      margin: 0;
      padding: 0;
      display: block;
      width: 100%; }
      @media (min-width: 20em) and (max-width: 75em) {
        .text-image-list .extra h5 {
          font-size: calc( 1em + 0 * ( ( 100vw - 20em) / 55 )); } }
      @media (min-width: 75em) {
        .text-image-list .extra h5 {
          font-size: 1em; } }
      .text-image-list .extra h5::selection {
        background: #fef3ec;
        color: #f7b082; }
    .text-image-list .extra:after {
      display: block;
      z-index: 499;
      width: 7.1875rem;
      height: 7.1875rem;
      background: #f7b082;
      position: relative;
      margin: 0 auto;
      top: 17%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      content: ''; }
    .text-image-list .extra span {
      display: block;
      margin: 0.9375rem 0 0 0;
      font-size: 0.77768em;
      text-transform: none;
      position: relative; }
      @media (min-width: 20em) and (max-width: 75em) {
        .text-image-list .extra span {
          font-size: calc( 0.77768em + -0.14372 * ( ( 100vw - 20em) / 55 )); } }
      @media (min-width: 75em) {
        .text-image-list .extra span {
          font-size: 0.63396em; } }
      .text-image-list .extra span::selection {
        background: #fef3ec;
        color: #f7b082; }
      .text-image-list .extra span:before {
        margin: 0 auto 0.625rem auto;
        display: block;
        width: 1.375rem;
        height: 0.125rem;
        background: #0a0a0a;
        content: ''; }

.text-image .large-12:after {
  display: none; }

.text-image .large-12 .text-wrap-block {
  width: 100%;
  position: relative; }
  @media screen and (min-width: 40em) {
    .text-image .large-12 .text-wrap-block {
      max-width: 56%;
      margin-right: 4%;
      float: left;
      clear: left; } }
  @media screen and (min-width: 40em) {
    .text-image .large-12 .text-wrap-block .wrap-height {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%); } }

.text-image .large-12 p {
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.0125rem; }

.text-image .large-12 p:last-of-type {
  margin-bottom: 0 !important; }

.text-image .large-12 .button {
  margin-top: 1.875rem; }

.text-image .large-12 figure {
  width: 100%;
  position: relative;
  display: block;
  margin-bottom: 30px; }
  @media screen and (min-width: 40em) {
    .text-image .large-12 figure {
      float: right;
      clear: right;
      width: 40%;
      max-width: 20.625rem; } }
  .text-image .large-12 figure img {
    z-index: 500;
    position: relative;
    width: 100%; }
  .text-image .large-12 figure .shape {
    z-index: 501;
    position: absolute;
    display: block;
    content: '';
    background-size: contain;
    width: 130px;
    height: 130px;
    top: 50%;
    left: -65px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%); }

.text-image .large-12.align-right:after {
  float: right; }

@media screen and (min-width: 40em) {
  .text-image .large-12.align-right .text-wrap-block {
    float: right;
    clear: right;
    margin-right: 0;
    margin-left: 4%; } }

.text-image .large-12.align-right figure {
  float: left;
  clear: left; }
  .text-image .large-12.align-right figure .shape {
    left: auto;
    right: -65px; }

.text-image .large-12:after {
  display: none !important;
  border: none !important;
  background: transparent !important; }

.feature {
  background: #47515d url("../img/orange.svg") no-repeat 105% 120%;
  background-size: 33%;
  color: #f7f5f2;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  margin-bottom: 3.75rem; }
  @media screen and (max-width: 63.9375em) {
    .feature {
      background: #47515d url("../img/orange.svg") no-repeat 105% 105%;
      background-size: 33%; } }
  @media screen and (max-width: 39.9375em) {
    .feature {
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem; } }
  .feature .text-image {
    max-width: 76.875rem;
    margin-left: auto;
    margin-right: auto; }
    .feature .text-image:after {
      border-color: #f7b082 !important; }
    @media screen and (min-width: 40em) {
      .feature .text-image figure {
        width: 40%;
        max-width: 29.375rem; } }
    .feature .text-image .large-12:after {
      border: none !important;
      background: transparent !important; }
    .feature .text-image .large-12 h3 {
      font-size: 1.65349em;
      text-transform: capitalize;
      text-align: left;
      position: relative;
      line-height: 3.75rem;
      padding: 0;
      margin-left: 1.875rem;
      margin-right: 0;
      min-height: 3.75rem;
      max-width: 56.25rem;
      letter-spacing: 0.125rem; }
      @media (min-width: 20em) and (max-width: 75em) {
        .feature .text-image .large-12 h3 {
          font-size: calc( 1.65349em + 0.83468 * ( ( 100vw - 20em) / 55 )); } }
      @media (min-width: 75em) {
        .feature .text-image .large-12 h3 {
          font-size: 2.48817em; } }
      @media screen and (max-width: 63.9375em) {
        .feature .text-image .large-12 h3 {
          padding-top: 8px; } }
      @media screen and (max-width: 39.9375em) {
        .feature .text-image .large-12 h3 {
          padding-top: 0;
          line-height: 1.3; } }
      .feature .text-image .large-12 h3 span {
        display: inline-block;
        position: relative; }
        .feature .text-image .large-12 h3 span:before, .feature .text-image .large-12 h3 span:after {
          display: block;
          content: '';
          width: 0.875rem;
          height: 0.125rem;
          background: #f7f5f2;
          position: absolute;
          left: -1.875rem;
          top: 50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          z-index: 501; }
        .feature .text-image .large-12 h3 span:after {
          left: auto;
          right: -1.875rem; }
    .feature .text-image .large-12 .button:hover {
      background: #f7f5f2;
      color: #47515d; }
    .feature .text-image .large-12:after {
      display: block;
      width: 100%; }
    .feature .text-image .large-12:last-of-type:after {
      display: none; }
  .feature .text-image:last-of-type:after {
    border-color: transparent !important;
    padding-top: 0.0625rem !important; }

.text-feature {
  background: #47515d url("../img/orange.svg") no-repeat center center;
  background-size: 33%;
  color: #f7f5f2;
  padding-top: 9.375rem;
  padding-bottom: 9.375rem;
  margin-bottom: 3.75rem;
  text-align: center; }
  @media screen and (max-width: 63.9375em) {
    .text-feature {
      background-size: 50%;
      padding-top: 3.125rem;
      padding-bottom: 3.125rem; } }
  .text-feature h2 span:after, .text-feature h2 span:before {
    background: #f7f5f2; }
  .text-feature .text-only {
    max-width: 45.625rem;
    margin-left: auto;
    margin-right: auto; }
    .text-feature .text-only:after {
      display: none !important; }

@media screen and (max-width: 39.9375em) {
  .centred-image-text {
    padding-bottom: 1.875rem; } }

.centred-image-text h3 {
  text-transform: capitalize;
  font-size: 1.65349em;
  text-align: center;
  position: relative;
  line-height: 3.75rem;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  min-height: 1.5625rem;
  padding-bottom: 0.3125rem;
  max-width: 56.25rem;
  letter-spacing: 0.125rem; }
  @media (min-width: 20em) and (max-width: 75em) {
    .centred-image-text h3 {
      font-size: calc( 1.65349em + 0.83468 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    .centred-image-text h3 {
      font-size: 2.48817em; } }
  @media screen and (max-width: 63.9375em) {
    .centred-image-text h3 {
      padding-top: 8px; } }
  @media screen and (max-width: 39.9375em) {
    .centred-image-text h3 {
      padding-top: 0;
      line-height: 1.3; } }
  .centred-image-text h3 span {
    display: inline-block;
    position: relative; }
    .centred-image-text h3 span:before, .centred-image-text h3 span:after {
      display: block;
      content: '';
      width: 0.875rem;
      height: 0.125rem;
      background: #47515d;
      position: absolute;
      left: -1.875rem;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      z-index: 501; }
    .centred-image-text h3 span:after {
      left: auto;
      right: -1.875rem; }

.centred-image-text figure {
  margin-top: 0; }
  @media screen and (max-width: 39.9375em) {
    .centred-image-text figure {
      margin-top: 0; } }
  .centred-image-text figure img {
    display: block;
    width: 100%;
    height: auto; }

.centred-image-text p {
  width: 100%;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem;
  line-height: 1.6;
  text-align: center;
  letter-spacing: 0.001rem; }

.centred-image-text ul {
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
  line-height: 1.8;
  text-align: center; }
  .centred-image-text ul li {
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase; }
    .centred-image-text ul li:before {
      display: none; }
    .centred-image-text ul li:after {
      position: relative;
      display: block;
      margin: 0.9375rem auto;
      width: 0.3125rem;
      height: 0.125rem;
      background: #0a0a0a;
      content: ''; }
  .centred-image-text ul li:last-of-type:after {
    display: none; }

.centred-image-two-cols .row {
  clear: both;
  margin: 0;
  padding: 0; }

@media screen and (max-width: 63.9375em) {
  .centred-image-two-cols {
    text-align: center; } }

.centred-image-two-cols h3 {
  text-align: center; }
  .centred-image-two-cols h3:after {
    position: relative;
    display: block;
    margin: 1.875rem auto;
    width: 3.4375rem;
    height: 0.125rem;
    background: #f7b082;
    content: ''; }

.centred-image-two-cols h4 {
  text-align: center;
  font-size: 1em;
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.8; }
  @media (min-width: 20em) and (max-width: 75em) {
    .centred-image-two-cols h4 {
      font-size: calc( 1em + 0 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    .centred-image-two-cols h4 {
      font-size: 1em; } }

.centred-image-two-cols figure {
  margin-bottom: 3.4375rem; }

.breakout-text-list {
  text-align: center; }
  .breakout-text-list .large-6:last-of-type {
    border-left: 0.125rem solid #f7b082;
    padding-left: 7%;
    padding-right: 5%; }
    @media screen and (max-width: 39.9375em) {
      .breakout-text-list .large-6:last-of-type {
        border: none;
        padding-left: 5%;
        padding-right: 5%; } }
  .breakout-text-list h3 {
    text-align: left;
    position: relative;
    line-height: 1.25rem;
    padding: 0;
    min-height: 1.25rem;
    padding-bottom: 0.125rem;
    letter-spacing: 0.0625rem;
    margin-bottom: 1.5625rem; }
  .breakout-text-list p {
    line-height: 1.5;
    width: 100%;
    text-align: center;
    margin: 0 auto 1.25rem auto;
    font-size: 1.13397em;
    font-weight: 400 !important; }
    @media (min-width: 20em) and (max-width: 75em) {
      .breakout-text-list p {
        font-size: calc( 1.13397em + 0.12198 * ( ( 100vw - 20em) / 55 )); } }
    @media (min-width: 75em) {
      .breakout-text-list p {
        font-size: 1.25594em; } }
    @media screen and (min-width: 40em) {
      .breakout-text-list p {
        max-width: 80%; } }
  .breakout-text-list ul li {
    margin-bottom: 0.9375rem;
    text-align: left; }
  .breakout-text-list .large-6 {
    position: relative;
    display: block; }
  @media screen and (min-width: 40em) {
    .breakout-text-list .feature-content {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%); } }

.breakout-text-button {
  text-align: center;
  overflow: hidden; }
  .breakout-text-button h3 {
    text-align: left;
    position: relative;
    line-height: 1.25rem;
    padding: 0;
    min-height: 1.25rem;
    padding-bottom: 0.125rem;
    letter-spacing: 0.0625rem;
    margin-bottom: 1.5625rem; }
  .breakout-text-button p {
    line-height: 1.5;
    width: 100%;
    text-align: left;
    margin: 0 0 1.25rem 0;
    font-size: 1rem;
    max-width: 85%; }
    @media screen and (max-width: 39.9375em) {
      .breakout-text-button p {
        max-width: 100%;
        margin: 0 auto 1.25rem auto; } }
  .breakout-text-button .large-6 {
    position: relative;
    display: block; }
  @media screen and (min-width: 40em) {
    .breakout-text-button .large-6:last-of-type {
      border-left: 0.125rem solid #f7b082; } }
  .breakout-text-button .large-6:last-of-type p {
    text-align: center;
    max-width: 100%;
    font-size: 1.375rem;
    font-weight: 400 !important; }
  @media screen and (min-width: 40em) {
    .breakout-text-button .feature-content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 58%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%); } }

.text-only p {
  font-size: 1.125rem;
  line-height: 1.6; }

.centred-quote h3 {
  font-size: 0.88186em;
  text-align: center;
  position: relative;
  line-height: 1.25rem;
  padding: 0;
  min-height: 1.25rem;
  padding-bottom: 0.125rem;
  width: 16.5625rem;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.0625rem;
  margin-bottom: 1.5625rem; }
  @media (min-width: 20em) and (max-width: 75em) {
    .centred-quote h3 {
      font-size: calc( 0.88186em + -0.08565 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    .centred-quote h3 {
      font-size: 0.79621em; } }
  .centred-quote h3:before, .centred-quote h3:after {
    display: block;
    content: '';
    width: 0.375rem;
    height: 0.125rem;
    background: #47515d;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    z-index: 501; }
  .centred-quote h3:after {
    left: auto;
    right: 0; }

.centred-quote p {
  font-size: 1.13397em;
  text-transform: uppercase;
  text-align: center;
  line-height: 2;
  width: 100%;
  max-width: 29.0625rem;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  @media (min-width: 20em) and (max-width: 75em) {
    .centred-quote p {
      font-size: calc( 1.13397em + 0.12198 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    .centred-quote p {
      font-size: 1.25594em; } }

.location h3 {
  width: 100%;
  max-width: 16.375rem; }
  .location h3:after {
    margin: 1.25rem 0 1.875rem 0;
    display: block;
    width: 2.5rem;
    height: 0.125rem;
    background: #f7b082;
    content: ''; }

.location .medium-5 p {
  width: 100%;
  max-width: 22.8125rem; }

.location .row {
  width: 100%;
  margin: 0; }
  .location .row .column, .location .row .columns {
    padding-left: 0.375rem;
    padding-right: 0;
    margin-bottom: 0.375rem;
    position: relative;
    overflow: hidden; }
    .location .row .column img, .location .row .columns img {
      position: relative;
      z-index: 500;
      width: 100%; }
    .location .row .column .overlay, .location .row .columns .overlay {
      display: block;
      position: absolute;
      left: 0.375rem;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(247, 176, 130, 0.9);
      z-index: 501;
      opacity: 0;
      transition: all 0.25s ease-out; }
      .location .row .column .overlay h4, .location .row .columns .overlay h4 {
        font-size: 1.13397em; }
        @media (min-width: 20em) and (max-width: 75em) {
          .location .row .column .overlay h4, .location .row .columns .overlay h4 {
            font-size: calc( 1.13397em + 0.12198 * ( ( 100vw - 20em) / 55 )); } }
        @media (min-width: 75em) {
          .location .row .column .overlay h4, .location .row .columns .overlay h4 {
            font-size: 1.25594em; } }
      .location .row .column .overlay span, .location .row .columns .overlay span {
        padding: 1.875rem;
        position: absolute;
        display: block;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%); }
    .location .row .column:hover .overlay, .location .row .columns:hover .overlay {
      opacity: 1; }

.location #map {
  display: block;
  height: 550px;
  width: 100%;
  margin: 0 0 0 0; }

#map {
  display: block;
  height: 650px;
  width: 100%;
  margin: 1.5625rem 0 1.5625rem 0; }

.location.fullwidth h3 {
  max-width: 25rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto; }
  .location.fullwidth h3:after {
    margin: 1.25rem auto 1.875rem auto; }

.location.fullwidth p {
  max-width: 41.25rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125rem; }

.location.fullwidth .overlay span {
  padding: 1.875rem;
  position: absolute;
  display: block;
  width: 100%;
  top: auto !important;
  left: auto !important;
  bottom: 0 !important;
  transform: translate(0, 0) !important;
  -webkit-transform: translate(0, 0) !important;
  -ms-transform: translate(0, 0) !important;
  -o-transform: translate(0, 0) !important; }
  .location.fullwidth .overlay span p {
    text-align: left;
    margin-left: 0;
    margin-right: 0; }

.location.withmap p {
  max-width: 55.625rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto; }

.well-connected h3 {
  font-size: 1.875em; }
  @media (min-width: 20em) and (max-width: 75em) {
    .well-connected h3 {
      font-size: calc( 1.875em + 1.25 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    .well-connected h3 {
      font-size: 3.125em; } }

.well-connected .connected-section p {
  display: block;
  width: 100%;
  max-width: 35.3125rem;
  font-size: 1.0625rem; }

.well-connected .connected-section .disclaimer {
  color: #afa198;
  font-size: 0.875rem;
  letter-spacing: 0.01875rem;
  margin-bottom: 0;
  margin-top: 1.25rem; }

.well-connected .connected-section ul.tabs {
  margin: 2.1875rem 0 1.25rem 0; }
  .well-connected .connected-section ul.tabs li {
    padding: 0;
    margin-right: 2.5rem; }
    @media screen and (max-width: 34.375em) {
      .well-connected .connected-section ul.tabs li {
        margin-right: 1.25rem; } }
    .well-connected .connected-section ul.tabs li a {
      font-size: 1.625rem;
      color: #a5a5a5;
      letter-spacing: 0.125rem; }
      @media screen and (max-width: 34.375em) {
        .well-connected .connected-section ul.tabs li a {
          font-size: 1.25rem; } }
      .well-connected .connected-section ul.tabs li a:hover {
        color: #f7b082; }
    .well-connected .connected-section ul.tabs li:before {
      display: none; }
    .well-connected .connected-section ul.tabs li:after {
      display: block;
      content: ".";
      position: absolute;
      bottom: 0;
      right: -1.375rem;
      font-size: 1.625rem;
      line-height: 1;
      color: #a5a5a5; }
      @media screen and (max-width: 34.375em) {
        .well-connected .connected-section ul.tabs li:after {
          right: -0.6875rem;
          font-size: 1.25rem; } }
  .well-connected .connected-section ul.tabs li.is-active a {
    color: #f7b082; }
  .well-connected .connected-section ul.tabs li:nth-child(3n+1) a:hover {
    color: #47515d; }
  .well-connected .connected-section ul.tabs li:nth-child(3n+1).is-active a {
    color: #47515d; }
  .well-connected .connected-section ul.tabs li:nth-child(3n+2) a:hover {
    color: #f7b082; }
  .well-connected .connected-section ul.tabs li:nth-child(3n+2).is-active a {
    color: #f7b082; }
  .well-connected .connected-section ul.tabs li:nth-child(3n+3) a:hover {
    color: #f7b082; }
  .well-connected .connected-section ul.tabs li:nth-child(3n+3).is-active a {
    color: #f7b082; }
  .well-connected .connected-section ul.tabs li:last-of-type:after {
    display: none; }

.well-connected .connected-section .tabs-content ul {
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 550;
  overflow: hidden;
  margin-left: 0;
  font-size: 0; }
  .well-connected .connected-section .tabs-content ul:before {
    content: "";
    display: block;
    vertical-align: middle;
    margin: 0 0;
    position: absolute;
    z-index: 450;
    background: #f7b082; }
    @media screen and (min-width: 31.3125em) {
      .well-connected .connected-section .tabs-content ul:before {
        width: 80%;
        height: 0.625rem;
        top: 40%;
        left: 10%;
        background-attachment: fixed !important;
        -webkit-transform: translateY(-60%);
        -ms-transform: translateY(-60%);
        transform: translateY(-60%);
        -o-transform: translateY(-60%); } }
    @media screen and (max-width: 31.25em) {
      .well-connected .connected-section .tabs-content ul:before {
        width: 0.625rem;
        height: 80%;
        top: 10%;
        left: 50%;
        background-attachment: fixed !important;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        -o-transform: translateX(-50%); } }
  .well-connected .connected-section .tabs-content ul li {
    margin: 0 4% 0 0;
    padding: 1.875rem 0;
    display: inline-block;
    line-height: 1;
    text-align: center;
    position: relative;
    z-index: 660;
    vertical-align: top;
    width: 16%;
    letter-spacing: 0.0625rem; }
    @media screen and (max-width: 31.25em) {
      .well-connected .connected-section .tabs-content ul li {
        width: 100%;
        margin: 0;
        padding: 0.625rem 0 1.25rem 0; } }
    .well-connected .connected-section .tabs-content ul li strong.place {
      display: inline-block;
      font-size: 0.6875rem;
      width: 100%;
      max-width: 70%;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.3;
      text-transform: none;
      height: 1.875rem;
      text-transform: uppercase;
      letter-spacing: 0.125rem; }
      @media screen and (max-width: 46.875em) {
        .well-connected .connected-section .tabs-content ul li strong.place {
          max-width: 100%;
          height: auto; } }
    .well-connected .connected-section .tabs-content ul li:before {
      display: none; }
    .well-connected .connected-section .tabs-content ul li span {
      display: block;
      width: 7.1875rem;
      height: 7.1875rem;
      border: solid 0.625rem #e8e8e8;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      text-align: center;
      font-size: 1.0625rem;
      margin: 0 auto;
      padding: 0 0;
      background: #fff;
      color: #e8e8e8;
      margin-bottom: 1.25rem;
      position: relative;
      vertical-align: top;
      background: #f7f5f2 url(../img/bg.jpg) repeat left top; }
      @media screen and (max-width: 46.875em) {
        .well-connected .connected-section .tabs-content ul li span {
          width: 5.625rem;
          height: 5.625rem;
          margin-bottom: 0.3125rem; } }
      .well-connected .connected-section .tabs-content ul li span strong {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        display: block;
        font-size: 1.0625rem;
        font-weight: 400 !important;
        line-height: 1;
        position: absolute;
        top: 50%;
        text-align: center;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        -o-transform: translateY(-50%);
        height: auto;
        background: transparent;
        color: #47515d;
        text-transform: none !important; }
        @media screen and (max-width: 46.875em) {
          .well-connected .connected-section .tabs-content ul li span strong {
            font-size: 0.9375rem; } }
        .well-connected .connected-section .tabs-content ul li span strong b {
          font-weight: 400;
          display: block;
          font-size: 1.875rem; }
          @media screen and (max-width: 46.875em) {
            .well-connected .connected-section .tabs-content ul li span strong b {
              font-size: 1.25rem; } }
  .well-connected .connected-section .tabs-content ul li:last-of-type {
    margin-right: 0; }

.well-connected .connected-section .tabs-panel:nth-child(3n+1) li span {
  border-color: #47515d; }

.well-connected .connected-section .tabs-panel:nth-child(3n+2) li span {
  border-color: #f7b082; }

.well-connected .connected-section .tabs-panel:nth-child(3n+3) li span {
  border-color: #f7b082; }

.three-col h5 {
  font-size: 1rem; }

.three-col img {
  display: block;
  width: 100%;
  margin: 0 auto 1.25rem auto;
  max-width: 21.25rem; }
  @media screen and (min-width: 40em) {
    .three-col img {
      margin: 0 0 1.25rem 0; } }

.three-col p {
  width: 100%;
  font-size: 1rem;
  margin: 0 0 1.875rem 0;
  line-height: 1.7; }
  @media screen and (min-width: 40em) {
    .three-col p {
      max-width: 21.25rem;
      margin: 0 0 1.25rem 0; } }

.housing-choice {
  overflow: hidden; }
  .housing-choice p {
    max-width: 53.125rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.25rem; }
  .housing-choice .row {
    margin-right: 0;
    margin-left: 0;
    margin-top: 1.875rem; }
    .housing-choice .row .column, .housing-choice .row .columns {
      padding-right: 10%;
      padding-left: 0;
      padding-bottom: 1.875rem;
      border-right: 0.125rem solid #f7b082;
      border-bottom: 0.125rem solid #f7b082; }
      @media screen and (max-width: 39.9375em) {
        .housing-choice .row .column, .housing-choice .row .columns {
          border-right: none !important;
          padding-right: 0 !important; } }
      .housing-choice .row .column p, .housing-choice .row .columns p {
        text-align: left;
        margin: 0;
        font-size: 1rem; }
      .housing-choice .row .column img, .housing-choice .row .columns img {
        margin-bottom: 1.875rem; }
      .housing-choice .row .column h5, .housing-choice .row .columns h5 {
        font-size: 1.625rem;
        margin-top: 1.875rem;
        text-transform: none;
        letter-spacing: 0.001rem !important; }
    .housing-choice .row .column:nth-child(2n), .housing-choice .row .columns:nth-child(2n) {
      border-right: 0.125rem solid #f7f5f2;
      padding-left: 10%;
      padding-right: 0; }
      @media screen and (max-width: 39.9375em) {
        .housing-choice .row .column:nth-child(2n), .housing-choice .row .columns:nth-child(2n) {
          padding-left: 0;
          padding-top: 3.75rem; } }
    .housing-choice .row .column:nth-child(n+3), .housing-choice .row .columns:nth-child(n+3) {
      padding-top: 3.75rem;
      padding-bottom: 0;
      border-bottom: 0.125rem solid #f7f5f2; }
      @media screen and (max-width: 39.9375em) {
        .housing-choice .row .column:nth-child(n+3), .housing-choice .row .columns:nth-child(n+3) {
          padding-bottom: 1.875rem;
          border-bottom: 0.125rem solid #f7b082; } }

.faq {
  overflow: hidden; }
  .faq .accordion {
    background: transparent; }
    .faq .accordion li {
      padding-left: 0;
      margin-bottom: 0;
      border-bottom: 0.0625rem solid #eae8e6; }
      .faq .accordion li:before {
        display: none; }
    .faq .accordion .accordion-title {
      position: relative;
      display: block;
      padding: 1.125rem 0;
      border: none;
      font-size: 1rem;
      line-height: 1;
      color: #47515d; }
      .faq .accordion .accordion-title:before {
        color: #f7b082;
        font-size: 1.25rem;
        font-weight: 400; }
      .faq .accordion .accordion-title:hover, .faq .accordion .accordion-title:focus {
        background-color: transparent; }
    .faq .accordion .accordion-content {
      padding: 0.9375rem 0 0.9375rem 0;
      border: none;
      background-color: transparent;
      color: rgba(71, 81, 93, 0.7);
      font-size: 0.875rem !important;
      color: rgba(71, 81, 93, 0.7);
      font-weight: 400 !important;
      line-height: 1.7; }

.downloads .large-12 {
  overflow: hidden;
  position: relative;
  padding-bottom: 2.8125rem; }

.downloads h3, .downloads p {
  text-align: center;
  width: 100%;
  max-width: 34.375rem;
  margin-left: auto;
  margin-right: auto;
  position: relative; }

.downloads p {
  font-size: 18px; }

.downloads .row {
  margin-top: 2.8125rem;
  text-align: center;
  width: 100%;
  max-width: 35.9375rem !important;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  .downloads .row .download-item img {
    margin-bottom: 1.25rem; }
  .downloads .row .download-item a {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: block; }
  .downloads .row .download-item .button {
    max-width: 150px;
    margin: 0 auto; }
  .downloads .row .download-item:last-of-type {
    padding-top: 1.875rem; }
    @media screen and (min-width: 40em) {
      .downloads .row .download-item:last-of-type {
        padding-top: 0; } }
  .downloads .row .download-item.add-border:last-of-type {
    padding-top: 1.875rem; }
    @media screen and (min-width: 40em) {
      .downloads .row .download-item.add-border:last-of-type {
        padding-top: 0;
        border-left: 0.125rem solid #f7b082; } }

.signup:after {
  display: none !important; }

.signup h3 {
  font-size: 1.875em;
  text-align: center;
  width: 100%;
  background: url("../img/border.png") repeat-x center center;
  min-height: 3rem;
  line-height: 3rem;
  margin-top: 3.125rem; }
  @media (min-width: 20em) and (max-width: 75em) {
    .signup h3 {
      font-size: calc( 1.875em + 1.25 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    .signup h3 {
      font-size: 3.125em; } }
  .signup h3 span {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    min-height: 3rem;
    padding: 0 1.25rem;
    background: #f7f5f2; }

.signup h4 {
  font-size: 1em;
  text-align: center;
  width: 100%;
  margin-bottom: 0.625rem;
  margin-top: 1.875rem; }
  @media (min-width: 20em) and (max-width: 75em) {
    .signup h4 {
      font-size: calc( 1em + 0 * ( ( 100vw - 20em) / 55 )); } }
  @media (min-width: 75em) {
    .signup h4 {
      font-size: 1em; } }

.signup h4 + p {
  font-size: 1rem;
  text-align: center;
  width: 100%;
  margin-bottom: 1.25rem !important; }
  .signup h4 + p:after {
    position: relative;
    display: none;
    margin: 1.875rem auto;
    width: 2.5rem;
    height: 0.375rem;
    background: #f7b082;
    content: ''; }

.signup p {
  text-align: center;
  width: 100%;
  max-width: 41.25rem;
  margin-left: auto;
  margin-right: auto; }
  .signup p a {
    color: #47515d; }

.signup .button {
  margin: 3.125rem auto 1.875rem auto;
  width: 100%;
  max-width: 16.5625rem;
  display: block;
  background: #f7b082;
  border: none; }
  .signup .button:hover {
    background: #242f3a; }

.standard-text p, .standard-text li {
  font-size: 0.875rem;
  line-height: 1.6;
  letter-spacing: 0 !important; }

.contentsection:after {
  display: block;
  border-bottom: 0.125rem solid #f7f5f2;
  content: '';
  width: 98%;
  max-width: 75rem;
  height: 0.0625rem;
  padding: 4.0625rem 0 0 0;
  margin: 0 auto 0.625rem auto;
  clear: both; }
  @media screen and (min-width: 64em) {
    .contentsection:after {
      width: 100%;
      margin: 0 auto 4.0625rem auto; } }

.sameblock:after {
  display: block;
  border-bottom: 0.125rem solid #f7f5f2;
  content: '';
  width: 98%;
  max-width: 75rem;
  height: 0.0625rem;
  padding: 0.0625rem 0 0 0;
  margin: 0 auto 0.625rem auto;
  clear: both; }
  @media screen and (min-width: 64em) {
    .sameblock:after {
      width: 100%;
      margin: 0 auto 4.0625rem auto; } }

.divider:after {
  border-bottom: 0.125rem solid #f7b082; }

#reserve .contentsection:last-of-type:after, #register .contentsection:last-of-type:after {
  padding: 0.0625rem 0 0 0;
  margin: 0 auto 0.0625rem auto; }
  @media screen and (min-width: 64em) {
    #reserve .contentsection:last-of-type:after, #register .contentsection:last-of-type:after {
      margin: 0 auto 0.0625rem auto; } }

#register .text-only p, #reserve .text-only p {
  text-align: center;
  color: #afa198;
  font-size: 0.75rem;
  letter-spacing: 0.01875rem;
  margin-bottom: 0;
  margin-top: 1.25rem; }

.reveal-overlay {
  z-index: 99999;
  display: none;
  background-color: #47515d;
  width: 100vw !important;
  height: 100vh !important; }
  @media screen and (max-width: 39.9375em) {
    .reveal-overlay {
      width: 101vw !important;
      left: -3px !important; } }
  .reveal-overlay .close-button {
    position: absolute;
    top: 1%;
    right: 2%;
    z-index: 9999999;
    font-size: 5.625rem;
    font-weight: 100 !important;
    font-family: "Times New Roman", serif !important; }
    @media screen and (max-width: 39.9375em) {
      .reveal-overlay .close-button {
        font-size: 5rem;
        top: -0.9375rem;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%); } }

.reveal {
  z-index: 999991;
  padding: 0;
  border: none;
  border-radius: 0;
  width: 100%;
  max-width: 100%;
  background: transparent;
  color: #f7f5f2;
  top: 0 !important; }
  .reveal .revealWrap {
    max-width: 800px;
    margin: 0 auto;
    overflow: hidden; }
  .reveal .medium-6 {
    position: relative; }
    @media screen and (min-width: 40em) {
      .reveal .medium-6 {
        height: 20.3125rem;
        margin-bottom: 1.875rem; } }
  @media screen and (max-width: 39.9375em) {
    .reveal .registerlogo {
      padding: 0 !important; } }
  @media screen and (min-width: 40em) {
    .reveal .registerInfo {
      position: absolute;
      bottom: 0;
      left: 5%;
      width: 90%; }
      .reveal .registerInfo p {
        margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      padding-bottom: 2.8125rem; } }
  .reveal h4 {
    position: relative;
    font-size: 1.375rem; }
  .reveal p {
    font-size: 1.125rem; }
  .reveal form .medium-6 {
    height: auto;
    margin-bottom: 0; }
  .reveal form .input-group {
    display: block !important;
    font-size: 0.75rem; }
  .reveal form ul {
    width: 80%;
    float: left;
    clear: none; }
    .reveal form ul li {
      padding-left: 0 !important;
      font-size: 0.75rem !important;
      margin-top: 0.3125rem !important;
      margin-bottom: 0 !important;
      overflow: hidden; }
      .reveal form ul li label {
        font-size: 0.75rem !important;
        line-height: 0.75rem !important;
        text-transform: none;
        font-weight: 400 !important;
        float: left;
        clear: none;
        margin: 0 !important;
        width: 90%;
        letter-spacing: initial; }
      .reveal form ul li input {
        margin-bottom: 0 !important;
        line-height: 0.75rem !important;
        height: 1.125rem !important;
        font-size: 0.75rem;
        float: left;
        clear: none;
        width: auto;
        text-align: left;
        margin-right: 2.5%; }
      .reveal form ul li:before {
        display: none; }
  .reveal form label {
    color: #f7f5f2;
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.125rem; }
  .reveal form input:not(.button) {
    border: none;
    border-bottom: 0.1875rem solid #f7f5f2;
    background: #47515d !important;
    color: #f7f5f2;
    font-size: 0.875rem;
    height: 2.1875rem;
    line-height: 2.1875rem;
    margin-bottom: 2.1875rem;
    box-shadow: none !important;
    padding-left: 0;
    letter-spacing: 0.125rem; }
  .reveal form textarea {
    border: 0.25rem solid #f7f5f2;
    background: #f7f5f2 !important;
    color: #47515d;
    font-size: 0.875rem;
    height: 9.375rem;
    box-shadow: none !important;
    margin-bottom: 2.125rem; }
  .reveal form .button {
    float: right;
    clear: none;
    background: #f7b082;
    border: none !important;
    font-size: 0.8125rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
    .reveal form .button:hover {
      background: #f7f5f2;
      color: #47515d; }
  .reveal form input.mce_inline_error::-webkit-input-placeholder {
    color: #f7f5f2 !important;
    text-transform: uppercase; }
  .reveal form input::-webkit-input-placeholder {
    color: #f7f5f2 !important;
    text-transform: uppercase; }
  .reveal form input::-moz-placeholder {
    color: #f7f5f2 !important;
    text-transform: uppercase; }
  .reveal form input::-ms-input-placeholder {
    color: #f7f5f2 !important;
    text-transform: uppercase; }
  .reveal form input::-moz-placeholder {
    color: #f7f5f2 !important;
    text-transform: uppercase; }
  .reveal form label.error {
    display: block;
    float: none;
    width: auto;
    margin-left: 0;
    text-align: left;
    padding: .5em 0; }
  .reveal form div.mce_inline_error {
    display: none !important; }
  .reveal form div.mce_inline_error:first-of-type {
    display: block !important;
    color: #e85c41 !important; }
  .reveal form input.mce_inline_error {
    border-color: #e85c41 !important;
    color: #e85c41 !important; }

footer {
  background: #47515d;
  padding: 2.8125rem 0;
  margin-top: 2.8125rem;
  color: #f7f5f2; }
  @media screen and (max-width: 63.9375em) {
    footer .large-4 {
      margin-top: 3.75rem;
      text-align: center; } }
  @media screen and (min-width: 40em) {
    footer .first {
      border-right: 0.125rem solid #f7f5f2; } }
  footer .columns {
    position: relative; }
  footer h5 {
    font-size: 0.625rem;
    position: absolute;
    top: 0; }
  footer p {
    font-size: 0.75rem;
    font-weight: 400 !important; }
    footer p a {
      border: none !important;
      color: #f7f5f2 !important;
      text-transform: none;
      font-size: 0.75rem;
      font-weight: 400 !important; }
  footer p.legal {
    max-width: 100%;
    margin-top: 0; }
  footer p:last-of-type {
    margin-bottom: 0; }
  footer ul {
    list-style-type: none;
    display: inline-block;
    margin: 0;
    width: 100%;
    height: auto; }
    footer ul li {
      display: block;
      float: left;
      clear: none;
      margin: 0;
      padding: 0;
      width: 20%;
      margin-right: 5%;
      text-align: left;
      position: relative;
      height: 68px !important; }
      footer ul li a {
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0; }
      footer ul li img {
        display: block;
        margin: 0;
        position: absolute;
        bottom: -0.3125rem; }
    footer ul li:before {
      display: none; }
  footer ul.development-by li {
    width: 45%;
    margin-right: 5%;
    text-align: left; }

.breakout h2 {
  margin-bottom: 1.875rem; }

.breakout:after {
  display: none !important; }

.fonts-loading body, .wf-loading body {
  opacity: 0; }
