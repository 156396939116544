// SCSS Mixins and Functions *************************************


//PX to REM calculator


@function toRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

//toRem(0px)


// Modular Scale font size


$modularscale: (
  base: 1em,
  ratio: 1
);

@debug ms(-1);
@debug ms();
@debug ms(0);
@debug ms(1);
@debug ms(2);

$modularscale: (
  base: 1em,
  ratio: 1,
  20em: (
    ratio: 1.875at5
  ),
  75em: (
    ratio: 3.125at5
  ),
);

//4ems at scale 5, etc


// Text selection styles - uses the text color as the background and lightens it for readability, or uses black if need be
@mixin selectiondefault($color) {
  &::selection {
    background:scale-color($color, $lightness: 85%);
      @if lightness($color) >= 75% {
        color:$baseBlack;
      }@else {
        color:$color;
      }
    }
}



// This standardises margins and padding, based on the responsive gutter set in Foundation
@mixin standardgutter($type, $position, $mathby) {
  @each $breakpoint, $value in $grid-column-gutter{

    $guttervalue:0;

      @if $mathby < 0{
        $mathbyfinal: $mathby * -1;
        $guttervalue: rem-calc($value) / $mathbyfinal;
      }@else{
        $guttervalue: rem-calc($value) * $mathby;
      }

      @include breakpoint($breakpoint) {
        @if $position == vertical {
            #{$type}:$guttervalue 0;
      }@else if $position == horizontal{
        #{$type}:0 $guttervalue;
      }@else if $position == all{
        #{$type}:$guttervalue;
      }@else{
        #{$type}-#{$position}:$guttervalue;
      }
        }
  }
}


/* Simple border mixin */
@mixin border($position, $size, $style, $color) {
  @if $size != 0 {
    border-#{$position}: $size $style $color;
  }@else{
    border:none;
  }
}

/* Simple fullborder mixin */
@mixin fullborder($size, $style, $color) {
  @if $size != 0 {
    border: $size $style $color;
  }@else{
    border:none;
  }
}



//SCSS varibles *************************************************


$baseWhite: 			#fff; // white	
$baseBlack: 			#000; // black

$backgroundColor: 		#f7f5f2; // light cream

$primaryColour:			#47515d; // dark blue

$signatureOne:			#47515d; // dark blue
$signatureTwo:			#f7b082; // light orange

$signatureOneAlpha: rgba(71,81,93, 0.70); // light blue - 90%
$signatureTwoAlpha: rgba(247,176,130, 0.90); // light orange - 90%

$textColour:			#47515d; // dark blue

$borderColour:		#f7b082; // light orange
$grey:            #a5a5a5; // mid grey
$lightGrey:       #eae8e6; // light grey

$signatureOneAlphaLight: rgba(71,81,93, 0.05); // light blue - 5%

$headerFontFamily:		'u8bold';
$subheaderFontFamily: 'filosofia';
$bodyFontFamily:		  'filson-pro';


/* Modular Scale*/
$header-sizes: (
  small: (
    'h1': 4,
    'h2': 3,
    'h3': 0,
    'h4': 0,
    'h5': 0,
    'h6': 0,
  ),
  medium: (
    'h1': 4,
    'h2': 3,
    'h3': 0,
    'h4': 0,
    'h5': 0,
    'h6': 0,
  ),
);

//Font declarations ********************************************

@font-face {
    font-family: 'u8bold';
    src: url('../fonts/u8-bold-webfont.woff2') format('woff2'),
         url('../fonts/u8-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


.header-font{
  font-family: $headerFontFamily, Arial, sans-serif !important;
  font-style: normal;
  font-weight:normal;
}

.subheader-font{
  font-family: $subheaderFontFamily, Arial, sans-serif !important;
  font-style: normal;
  font-weight:700;
}

.body-font-bold{
  font-family: $bodyFontFamily, Arial, sans-serif;
  font-style: normal;
  font-weight:700;
}

.body-font-medium{
  font-family: $bodyFontFamily, Arial, sans-serif;
  font-style: normal;
  font-weight:400;
}

.body-font-book{
  font-family: $bodyFontFamily, Arial, sans-serif !important;
  font-style: normal;
  font-weight:500;
}

.body-font-light{
  font-family: $bodyFontFamily, Arial, sans-serif;
  font-style: normal;
  font-weight:400;
}

.self-clear{
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}



//Foundation Grid Override **************************************


.row {
  max-width: toRem(1230px);
}

.row .row{
	max-width: 100%;
}


.row.fullwidth {
  max-width: 100%;
}

section{
  position: relative;
  display: block;

  @include breakpoint(1023px down) {
    max-width: 99% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

}


//Global Styles *************************************************



*:focus {
outline: 0 !important;
}



body {
	font-size: ms(0);
	background: $backgroundColor;
	color: $textColour;
	font-family: sans-serif;
	opacity: 1;  
	transition: transform .3s ease-in-out, opacity .3s ease-in-out;
	-webkit-transition: -webkit-transform .3s ease-in-out, opacity .3s ease-in-out;
}


p{
	@include selectiondefault($textColour);
  font-size: toRem(16px);
	@include standardgutter(margin, bottom, 1); //note -minus numbers here means divide by
  line-height: 1.4;

  strong{
    @include selectiondefault($textColour);
    font-weight: 700 !important;
  }

  a{
    color:$textColour;
    padding-bottom: toRem(2px);
    @include selectiondefault($textColour);
    @include border(bottom, toRem(2px), solid, $signatureTwo);

    &:hover{
      color: $signatureTwo;
      @include selectiondefault($signatureTwo);
    }

    &:visited{
      color: $signatureTwo;
      @include selectiondefault($signatureTwo);
    }

  }

}

span, img{
  @include selectiondefault($textColour);
}

p + p{
  //@include ms-respond(text-indent, 0);
  //font-size: ms(0);
}



// h1, h2, h3, h4, h5, h6
  @each $size, $headers in $header-sizes {
      @each $header, $font-size in $headers {
        #{$header} {

	    @include ms-respond(font-size, $font-size);
			@include selectiondefault($primaryColour);
			@include standardgutter(margin, bottom, -2); 
			font-family: sans-serif;

			text-transform: uppercase;
			letter-spacing: toRem(1.5px) !important;
			
	        strong{}
	        & + p{}

        }
      }
  }


  .sectiontitle{
    h2{

      text-transform: capitalize;
      @include ms-respond(font-size, 4);
      text-align: center;
      position: relative;
      line-height: toRem(60px);
      padding:0;
      margin-left: auto;
      margin-right: auto;
      min-height:toRem(60px);
      padding-bottom:toRem(30px);
      max-width:toRem(900px);
      letter-spacing: toRem(2px);

      @include breakpoint(medium down) {
        padding-top: toRem(15px);
        padding-bottom: toRem(15px);
      }

      @include breakpoint(small only) {
        line-height: 1.3;
      }

      @include breakpoint(700px down) {
        max-width:85%;
      }

      span{
        display: inline-block;
        position: relative;

        @include breakpoint(700px down) {
          max-width:90%;
        }


        &:before, &:after{
          display: block;
          content: '';
          width:toRem(14px);
          height:toRem(2px);
          background: $signatureOne;
          position: absolute;
          left:toRem(-30px);
          top:50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          z-index: 501;
        }


        &:after{
          left:auto;
          right:toRem(-30px);
        }

        }

    }

  }




ul{
  margin:0;
  padding: 0;

  li{
    text-transform: none;
    margin: 0 0 toRem(15px) 0;
    list-style-type: none;
    position: relative;
    padding-left: toRem(20px);
    line-height: 1.4;
    display: block;


    @include selectiondefault($textColour);

    &:before{
      position: absolute;
      left:0;
      top:toRem(9px);
      display: block;
      width: toRem(5px);
      height:toRem(5px);
      background:$signatureTwo;
      content: '';
      border-radius: 50%;
    }

    ul{
      margin-top: toRem(10px);

      li{

        font-size: toRem(13px);

        &:before{
          position: absolute;
          left:0;
          top:toRem(9px);
          display: block;
          width: toRem(5px);
          height:toRem(5px);
          background:$signatureOne;
          content: '';
          border-radius: 50%;
        }

      }

    }

  }
}


.slick-dots{
  li{
    &:before{
      display: none;
    }
  }
}

.columns{

  ul{
    li{
      letter-spacing: 0.001rem !important;
    }
  }

}



.button{
  @include ms-respond(font-size, -1);
  color: $signatureOne;
  background:$signatureTwo;
  text-transform: uppercase;
  padding:toRem(20px) toRem(30px);
  letter-spacing: toRem(1.5px);

  &:hover{
    background:$signatureOne;
    color: $backgroundColor;
  }

}


table{

  @include standardgutter(margin, bottom, 1);

  thead, tbody, tfoot {
      border: 1px solid $signatureOneAlpha;
      background-color: $backgroundColor;
  }

  tbody{ 
    tr:nth-child(even) {
      border-bottom: 0;
      background-color: $signatureOneAlphaLight;
    }
  }

}





//Structures  *************************************************


//Nav  *********

.mobile-nav-wrap{
  position: relative;
  width:100%;
  display: block;
  height:toRem(40px);
  display: none;

  @include breakpoint(950px down) {
      display: block !important;
      @include border(bottom, toRem(2px), solid, $signatureTwo);
  }


  .register{
    position: absolute;
    display: block;
    width: auto;
    height: auto;
    bottom:0;
    right:0;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: toRem(10px);
    width:45%;
    max-width: toRem(100px);
    color:$textColour !important;
    background: $signatureTwo !important;
    line-height: 28px;

    &:hover{
        color: $backgroundColor;
        background: $textColour;
      }

  }


}

.mobile-nav{
  position: absolute;
  display: block;
  width: toRem(21px);
  height: toRem(12px);
  top:toRem(13px);
  left:0;
}

a#open-nav {
  width: toRem(21px);
  height: toRem(12px);
  margin: 0 auto;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  display: block;
}

a#open-nav span {
  display: block;
  position: absolute;
  height: toRem(2px);
  width: 100%;
  background: $signatureOne;
  border-radius: toRem(2px);
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .20s ease-in-out;
}
a#open-nav span:nth-child(1) {
  top: 0;
}
a#open-nav span:nth-child(2), a#open-nav span:nth-child(3) {
  top: 50%;
}
a#open-nav span:nth-child(4) {
  top: 100%;
}

html.nav-opened a#open-nav span {
  background: $signatureTwo;
}
html.nav-opened a#open-nav span:nth-child(1) {
  top: 50%;
  width: 0%;
  left: 50%;
}
html.nav-opened a#open-nav span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
html.nav-opened a#open-nav span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
html.nav-opened a#open-nav span:nth-child(4) {
  top: 50%;
  width: 0%;
  left: 50%;
}





nav{
  position: relative;
  width:100%;
  display: block;
  background:$backgroundColor;
  margin-top: toRem(30px);
  padding: toRem(16px);

  @include breakpoint(950px down) {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
  }


  ul{
    display: block;
    max-width: toRem(1200px);
    margin:0 auto; 
    padding:0;

    font-family: $bodyFontFamily;

    @include breakpoint(950px down) {
      padding:toRem(15px) 0;
      display: none;
    }



    @include border(bottom, toRem(2px), solid, $signatureTwo);

    @extend .self-clear;

    li{
      display: block;
      margin-bottom: toRem(-2px) !important;
      padding:0 !important;
      float:left;
      clear:none;
      width:auto;
      margin-right: 5%;

      @include breakpoint(1040px down) {
        margin-right: 4%;
      }

      @include breakpoint(970px down) {
        margin-right: 3%;
      }

      &:before{
        display: none;
      }



      @include breakpoint(950px down) {
        float:none;
        display: block;
        width:100%;
        margin-right: 0;
      }


      @include breakpoint(xlarge up) {
        //max-width: toRem(135px);
        //margin-right: toRem(10px);
      }

      a{
        font-size: toRem(11px);
        color: $textColour;
        text-transform: uppercase;
        display: block;
        width:100%;
        height:toRem(33px);
        line-height:toRem(33px);
        letter-spacing: toRem(1.2px);
        @include selectiondefault($textColour);

      }

      a.active, a:hover, a:focus{
        color: $signatureTwo;
        @include selectiondefault($signatureTwo);
      }


    }

    .additional{
      
      font-size: 0;
      max-width: toRem(220px);
      width:100%;
      text-align: right;
      float:right;
      clear:none;
      margin-right: 0;

      @include breakpoint(950px down) {
        display: none;
      }
        

      a{

        display: inline-block;
        text-align: center;
        font-size: toRem(11px);
        margin-left: 4%;

        width:48%;

        @include breakpoint(xlarge up) {
          max-width: toRem(110px);
        }

      }

      a.active, a:hover{
        color: $backgroundColor;
        background: $textColour;
      }

    }


    .register{
        background: $signatureTwo;
    }

    .brochure{
        background: $primaryColour;
        color: $white;
        margin-left: 0 !important;
    }


  }


}




//Nav stuck  *********
nav.stuck{

  margin-top: 0;
  position: fixed;
  top:0;
  left:0;
  z-index: 9999;

  h1#contracted{
    display: block !important;
  }


}





//Logo  *********
h1.logo{

  line-height: 1;

  span{
    background: url("../img/marmalade-lane.svg") no-repeat center;
    background-size: 70%;
    width:100%;
    height:100%;
    text-indent: -99999rem;
    display: block;
    margin:0;
  }

}



h1.logo#expanded{

    display: block;
    margin: 0;
    width: 100%;
    height:100%;

    @include breakpoint(950px down) {
      display: none !important;
  }
}


h1.logo#contracted{

  display: none !important;
  max-width: toRem(1200px);
  margin: toRem(10px) auto 0 auto;

  @include breakpoint(950px down) {
      display: block !important;
      line-height: 2;
      margin: toRem(10px) auto toRem(10px) auto;
  }

  span{
    background:url("../img/marmalade-lane-title.svg") no-repeat center left;
    background-size: 20%;
    height:auto;

    @include breakpoint(950px down) {
      display: block !important;
      background:url("../img/marmalade-lane-title-full.svg") no-repeat center center;
      background-size: 215px 50px;
    }

  }

  
}


.standard{
  h1.logo#contracted{
    display: block !important;
  }
}



.logo.register{

  line-height: 1;

  background-size: contain;

  background:$signatureTwo url("../img/marmalade-lane.svg") no-repeat center;
    background-size: 70%;
    width:100%;
    height:100%;
    text-indent: -99999rem;
    display: block;
    margin:0;
    padding:0;



  text-indent: -99999rem;
  display: block;
  margin: 0 0 toRem(40px) 0;

  @include ms-respond(font-size, 9);

  @include breakpoint(639px down) {
    background:$signatureTwo url("../img/marmalade-lane-title-full.svg") no-repeat center 78%;
    margin: 0 0 toRem(25px) 0; 
    max-width: 100%;  
    height:toRem(150px);
    background-size: 40%;
  }

}






//Header Section  *********


header{
  position: relative;
      background: url('../img/hero.jpg') top center no-repeat;
    background-size: cover;

    height:100vh;
    margin-top: toRem(10px);

    display: none;


  .large-12{
    max-width: toRem(1230px);
    margin:0 auto;

    position: relative;
    z-index: 500;
    float:none !important;
  }

  .branding{
    width:100%;
    height:100vh;
    max-width: toRem(415px);
    max-height: toRem(340px);
    margin:0;
    padding:0;
    background:$signatureTwo;
    display: block;

    @include breakpoint(950px down) {
        visibility: hidden;
    }

  }

}


.homepage{
  header{
    display: block;
  }
}


  .hero-content{

    margin-top: toRem(40px);

    @include breakpoint(1024px up) {
      margin-top: toRem(80px);
    }

    .large-7{
      @include breakpoint(1024px up) {
         @include border(right, toRem(2px), solid, $signatureTwo);
        padding-right: 0; 
      }
    }


    h3{
      @include ms-respond(font-size, 3);
      letter-spacing: toRem(2.2px) !important;
      
      @include breakpoint(1024px up) {
        padding-right: 33%;
      }

    }

    p{
      font-weight: 500;
      font-size: toRem(16px);
      line-height: 1.6;

      @include breakpoint(1024px up) {
        padding-right: 25%;
      }

    }

    p.subheader-font{
      font-size: toRem(22px);
      padding-bottom: toRem(30px);
      line-height: 1.4;
      @include border(bottom, toRem(2px), solid, $signatureTwo);
    }



    h4{
      font-size: toRem(26px);
      padding-right: toRem(30px);
      text-transform: none;
      font-weight: 700 !important;
      letter-spacing: 0.001rem !important;
    }


    .large-5{

      .awards{
        position: relative;
        display: block;
        width:100%;
        height:100%;

        @include breakpoint(1023px down) {
          @include border(top, toRem(2px), solid, $signatureTwo);
          margin-top: toRem(30px);
        }

      }
      
      h4{

        background:url('../img/orange.svg') center left no-repeat;
        background-size: 30%;
        text-align: left;
        width:100%;
        max-width: toRem(450px);
        padding:toRem(30px) 0 toRem(30px) toRem(160px);
        margin:0;
        display: block;
        font-size: toRem(17px);
        font-weight: 700 !important;
        letter-spacing: 0.028rem !important;

        margin:toRem(30px) auto 0 auto;

        @include breakpoint(1024px up) {

          background: url('../img/orange.svg') 40% 0 no-repeat;
          background-size: 33%;
          max-width: toRem(350px);
          padding:toRem(100px) 0 0 0;
          font-size: toRem(25px);
          margin:0;
          text-align: center;
          position: absolute;
          top:50%;
          left:50%;

          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);

        }

      

      }

    }


  }


#housetypes{
    margin: toRem(60px) auto !important;
    padding:0;
    text-align: center;
    max-width: toRem(1200px) !important;

    @include breakpoint(1200px down) {
     max-width: 98% !important;
    }


    .column-block{
      
      @include breakpoint(1024px up) {
        margin-bottom: 0;
        padding: 0;
        width:25%;
      }

      h4{
        line-height:1; 
        padding:0;
        @include ms-respond(font-size, 2);

        @include breakpoint(1200px down) {
          @include ms-respond(font-size, 1);
        }

      }

    }

    .first{
      
      @include breakpoint(1024px up) {
        text-align: left;
      }

      h4{
        color:$signatureTwo; 
      }
    }

    .second{
      
      @include breakpoint(1024px up) {
        width:50%;
      }

      h4{
        color:$grey; 
      }
    }

    .third {

      @include breakpoint(1024px up) {
        text-align: right;
      }

      h4{
        color:$signatureTwo; 
      }
    }


}



//Content Blocks *************************************************


.gallery{

  // margin-top: toRem(60px);

  .slick-slide{
    margin:0 toRem(10px);

    @include breakpoint(large up) {
      margin:0 toRem(35px);
    }

    figcaption{
      margin:toRem(20px) 0 0 0;
      width:100%;
      max-width:100%;
      display: block;
      opacity: 0;
      transition: opacity .2s ease-in-out;
      -webkit-transition: opacity .2s ease-in-out;

      @include breakpoint(medium up) {
        max-width:75%;
      }

      @include breakpoint(large up) {
        max-width:toRem(650px);
      }

      h5{
        @include ms-respond(font-size, 0);
      }

      p{
        @include ms-respond(font-size, 0);
      }

    }

  }

  .slick-current-first{
    figcaption{
      opacity: 1;
    }
  }

  .slick-current-stopped{
    figcaption{
      opacity: 1;
    }
  }

  .slick-dots{
    background: transparent;

    @include breakpoint(medium down) {
      right: toRem(25px);
      bottom:toRem(90px);
    }

    

  }



}





.text-image-list{

  padding:0 0 0 0;

  /*
    &:after{
      @include border(bottom, toRem(2px), solid, $borderColour);
      display: block;
      max-width: toRem(1200px);
      content: '';
      width:100%;
      height:auto;
      margin: toRem(50px) auto;
    }
    */


  .row{
    clear:both;
    margin:0;
    padding: toRem(40px) 0 0 0;
  }

  h3{
    //@include ms-respond(font-size, 3);


    width:100%;

    font-size: toRem(22px);
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    @extend .subheader-font;
    font-weight: 400 !important;


    @include breakpoint(xlarge) {
        max-width: 80%;
    }
    
  }

  p{
    width:100%;

    font-size: toRem(22px);
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    @extend .subheader-font;
    font-weight: 400 !important;


    @include breakpoint(xlarge) {
        max-width: 80%;
    }

  }




  ul{
    
    @include standardgutter(margin, top, 1); 

    @include breakpoint(large up) {
      @include standardgutter(margin, left, 1); 
      margin-top: 0;
    }

    @include breakpoint(1023px down) {
      margin-top: 0;
      margin-left: 1%;
    }

    @include breakpoint(600px down) {
      @include standardgutter(margin, top, 1); 
    }

    li{
      font-size: toRem(14px);
    }

  }


  figure{
    @include breakpoint(1023px down) {
      max-width: 98%;
      margin:0 auto toRem(30px) auto;
    }
  }


  img{
    width:100%;
    max-width: toRem(700px);
    margin: 0 auto;
    display: block;

    @include breakpoint(1023px down) {
      max-width: 100%;
    }

  }

  .extra{

   
    display: block;
    width:toRem(225px);
    height:toRem(175px);
    text-align: center;
    position: relative;
    margin-left:auto;
    margin-right: auto;

    @include breakpoint(medium up) {
      float: right;
      clear: none;
      margin-left:0;
      margin-right: 0;
    }

    h5{
      @include ms-respond(font-size, 0);
      z-index: 500;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      margin: 0;
      padding: 0;
      display: block;
      width: 100%;
      @include selectiondefault($signatureTwo);
    }

    &:after{
      display: block;
      z-index: 499;
      width:toRem(115px);
      height:toRem(115px);
      background: $signatureTwo;
      position: relative;
      margin:0 auto;
      top: 17%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      content:'';
    }

    span{
      display: block;
      margin: toRem(15px) 0 0 0;
      @include ms-respond(font-size, -2);
      text-transform: none;
      position: relative;
      @include selectiondefault($signatureTwo);

      &:before{
        margin: 0 auto toRem(10px) auto;
        display: block;
        width: toRem(22px);
        height:toRem(2px);
        background:$black;
        content: '';
      }


    }

  }


}




.text-image{

  .large-12{
 
    &:after{
        display: none;
    }

    .text-wrap-block{
      width:100%;
      position: relative;

      @include breakpoint(medium up) {
        max-width: 56%;
        margin-right: 4%;
        float: left;
        clear:left;
        //margin-bottom: toRem(60px);
      }

      .wrap-height{
        @include breakpoint(640px up) {
          position: absolute;
          top:50%;
          left:0;
          width:100%;

          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
            
        }
      }

    }


    h3{

    }

    p{
      font-size: toRem(16px);
      line-height: 1.6;
      letter-spacing:toRem(0.2px);
    }

    p:last-of-type{
      margin-bottom: 0 !important;
    }


    .button{
      margin-top: toRem(30px);
    }



    figure{
      width:100%;   
      position: relative;
      display: block;
      margin-bottom: 30px;

      @include breakpoint(medium up) {
        float:right;
        clear:right;
        width:40%;
        max-width: toRem(330px);
      }


      img{
        z-index: 500;
        position: relative;
        width:100%;
      }

      .shape{
        z-index: 501;
        position: absolute;
        display: block;
        content: '';
        //background:url("../img/orange-stripes.svg") no-repeat center center;
        background-size: contain;
        width:130px;
        height:130px;
        top: 50%;
        left:-65px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }

    }

    


  }



  .large-12.align-right{

     &:after{
        float:right; 
    }

    .text-wrap-block{
      @include breakpoint(medium up) {
        float: right;
      clear:right;
      margin-right: 0;
      margin-left: 4%;
      }
    }

 
    h3{
      
    }


    .button{
    }


    figure{

      float:left;
      clear:left;

      img{
      }

      .shape{
        left:auto;
        right:-65px;
      }

    }


  }

  .large-12:last-of-type{
    &:after{
    //display: none;
    }
  }



  .large-12:nth-child(3n+1){
    .shape{
        //background:url("../img/orange-stripes.svg") no-repeat center center;
      }
  }


  .large-12:nth-child(3n+2){
    .shape{
        //background:url("../img/hexagon.svg") no-repeat center center;
      }
  }

  .large-12:nth-child(3n+3){
    .shape{
        //background:url("../img/circle.svg") no-repeat center center;
      }
  }



  .shape.orangeLines{
      //background:url("../img/orange-stripes.svg") no-repeat center center !important;
    }


  .shape.greenHexagon{
        //background:url("../img/hexagon.svg") no-repeat center center !important;
    }

  .shape.blueCircle{
        //background:url("../img/circle.svg") no-repeat center center !important;
    }

  .shape.yellowDiamond{
      //background:url("../img/yellowDiamond.png") no-repeat center center !important;
  }




}



.text-image{

  .large-12{
    &:after{
      display: none !important;
      border: none !important;
      background: transparent !important;
    }
  }

}



.feature{


  background:$signatureOne  url("../img/orange.svg") no-repeat 105% 120%;
  background-size: 33%;

  color: $backgroundColor;

  padding-top: toRem(60px);
  padding-bottom: toRem(60px);
  margin-bottom: toRem(60px);


  @include breakpoint(1023px down) {
    background:$signatureOne  url("../img/orange.svg") no-repeat 105% 105%;
    background-size: 33%;
  }

  @include breakpoint(639px down) {
     padding-top: toRem(15px);
      padding-bottom: toRem(15px);
  }


  .text-image{

    max-width: toRem(1230px);
    margin-left:auto;
    margin-right:auto;

    &:after{
      border-color: $signatureTwo !important;
      //background: transparent !important;
    }

    figure{

      @include breakpoint(medium up) {
        width:40%;
        max-width: toRem(470px);
      }

    }

    .large-12{

      &:after{
        border: none !important;
        background: transparent !important;
      }

      h3{

      @include ms-respond(font-size, 4);
      text-transform: capitalize;
      text-align: left;
      position: relative;
      line-height: toRem(60px);
      padding:0;
      margin-left: toRem(30px);
      margin-right: 0;
      min-height:toRem(60px);
      max-width:toRem(900px);
      letter-spacing: toRem(2px);

      @include breakpoint(medium down) {
        padding-top: 8px;
      }

      @include breakpoint(small only) {
        padding-top: 0;
        line-height: 1.3;
      }

      span{
        display: inline-block;
        position: relative;


        &:before, &:after{
          display: block;
          content: '';
          width:toRem(14px);
          height:toRem(2px);
          background: $backgroundColor;
          position: absolute;
          left:toRem(-30px);
          top:50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          z-index: 501;
        }


        &:after{
          left:auto;
          right:toRem(-30px);
        }

        }

      }


      .button{
        &:hover{
          background: $backgroundColor;
          color: $signatureOne;
        }
      }



      &:after{
      display: block;
      width:100%;
      }
    }

    .large-12:last-of-type{
      &:after{
      display: none;
      }
    }

  }

  .text-image:last-of-type{
      &:after{
      border-color: transparent !important;
      padding-top:toRem(1px) !important;
      }
    }

}



.text-feature{


  background:$signatureOne  url("../img/orange.svg") no-repeat center center;
  background-size: 33%;


  color: $backgroundColor;

  padding-top: toRem(150px);
  padding-bottom: toRem(150px);

  margin-bottom: toRem(60px);

  text-align: center;


  @include breakpoint(1023px down) {
    background-size: 50%;
    padding-top: toRem(50px);
  padding-bottom: toRem(50px);
  }


  h2{

    span{
      &:after, &:before{
        background: $backgroundColor;
      }
    }

  }

  .text-only{

    max-width: toRem(730px);
    margin-left:auto;
    margin-right:auto;

    &:after{
      display: none !important;
    }

  }


}





.centred-image-text{

  @include breakpoint(639px down) {
     //margin-top: toRem(60px);
     padding-bottom: toRem(30px);
    }

  h3{
    text-transform: capitalize;
      @include ms-respond(font-size, 4);
      text-align: center;
      position: relative;
      line-height: toRem(60px);
      padding:0;
      margin-left: auto;
      margin-right: auto;
      min-height:toRem(25px);
      padding-bottom:toRem(5px);
      max-width:toRem(900px);
      letter-spacing: toRem(2px);

      @include breakpoint(medium down) {
        padding-top: 8px;
      }

      @include breakpoint(small only) {
        padding-top: 0;
        line-height: 1.3;
      }

      span{
        display: inline-block;
        position: relative;


        &:before, &:after{
          display: block;
          content: '';
          width:toRem(14px);
          height:toRem(2px);
          background: $signatureOne;
          position: absolute;
          left:toRem(-30px);
          top:50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          z-index: 501;
        }


        &:after{
          left:auto;
          right:toRem(-30px);
        }

        }

    }


  figure{

    margin-top: 0;
    //margin-bottom: toRem(25px);

    @include breakpoint(small only) {
      margin-top: 0;
    }


    img{
      display: block;
      width:100%;
      height:auto;
    }

  }


  p{
    width:100%;
    max-width: 740px;
    margin-left:auto;
    margin-right: auto;
    font-size: toRem(16px);
    line-height: 1.6;
    text-align: center;
    letter-spacing: 0.001rem;
  }

  ul{
    width:100%;
    max-width: 740px;
    margin: 0 auto;
    line-height: 1.8;
    text-align: center;

    li{
      font-size: toRem(14px);
      text-align: center;
      text-transform: uppercase;

      &:before{
        display: none;
      }

      &:after{
        position: relative;
        display: block;
        margin: toRem(15px) auto;
        width: toRem(5px);
        height:toRem(2px);
        background:$black;
        content: '';
      }

    }

    li:last-of-type{
      &:after{
        display: none;
      }
    }

  }


}





.centred-image-two-cols{

  @include breakpoint(639px down) {
     //margin-top: toRem(60px);
    }

  .row{
    clear:both;
    margin:0;
    padding:0;
  }

  @include breakpoint(1023px down) {
         text-align: center;
    }


  h3{
    text-align: center;

    &:after{
        position: relative;
        display: block;
        margin: toRem(30px) auto;
        width: toRem(55px);
        height:toRem(2px);
        background:$signatureTwo;
        content: '';
      }
  }

  h4{
    text-align: center;
    @include ms-respond(font-size, 0);
    width:100%;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto; 
    line-height: 1.8;
  }

  figure{
    margin-bottom: toRem(55px);
  }


}




.breakout-text-list{

  text-align: center;

  .large-6:last-of-type{
    @include border(left, toRem(2px), solid, $signatureTwo);
    padding-left: 7%;
    padding-right: 5%;

    @include breakpoint(639px down) {
      border:none;
      padding-left: 5%;
      padding-right: 5%;
    }


  }

  h3{

    text-align: left;
    position: relative;
    line-height: toRem(20px);
    padding:0;
    min-height:toRem(20px);
    padding-bottom:toRem(2px);
    letter-spacing: toRem(1px);
    margin-bottom: toRem(25px);

  }


  p{
    line-height: 1.5;
    width:100%;
    text-align: center;
    margin:0 auto toRem(20px) auto;
    @include ms-respond(font-size, 1);
    font-weight: 400 !important;
    

    @include breakpoint(640px up) {
      max-width: 80%;
    }

  }

  ul{
    li{
      margin-bottom: toRem(15px);
      text-align: left;
    }
  }

  .large-6{
    position: relative;
    display: block;
  }


  .feature-content{
    @include breakpoint(640px up) {
      position: absolute;
      top:50%;
      left:0;
      width:100%;

      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
        
    }
  }


}




.breakout-text-button{

  text-align: center;
  overflow: hidden;


  h3{

    text-align: left;
    position: relative;
    line-height: toRem(20px);
    padding:0;
    min-height:toRem(20px);
    padding-bottom:toRem(2px);
    letter-spacing: toRem(1px);
    margin-bottom: toRem(25px);

  }


  p{
    line-height: 1.5;
    width:100%;
    text-align: left;
    margin:0 0 toRem(20px) 0;
    font-size: toRem(16px);
    max-width: 85%;
    
    @include breakpoint(639px down) {
      max-width: 100%;
      margin:0 auto toRem(20px) auto;
    }

  }

  .large-6{
    position: relative;
    display: block;
  }


  .large-6:last-of-type{

    @include breakpoint(640px up) {
      @include border(left, toRem(2px), solid, $signatureTwo);
    }

    p{
      text-align: center;
      max-width: 100%;
      font-size: toRem(22px);
      font-weight: 400 !important;
    }

  }


  .feature-content{
    @include breakpoint(640px up) {

      position: absolute;
      top:50%;
      left:50%;
      width:58%;

      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
        
    }
  }



}






.text-only{

  @include breakpoint(639px down) {
     //margin-top: toRem(60px);
    }

  p{
    font-size:toRem(18px);
    line-height: 1.6;
  }

}





.centred-quote{

  h3{
    @include ms-respond(font-size, -1);

    text-align: center;
    position: relative;
    line-height: toRem(20px);
    padding:0;
    min-height:toRem(20px);
    padding-bottom:toRem(2px);
    width:toRem(265px);
    margin-left: auto;
    margin-right: auto;
    letter-spacing: toRem(1px);
    margin-bottom: toRem(25px);



    &:before, &:after{
      display: block;
      content: '';
      width:toRem(6px);
      height:toRem(2px);
      background: $textColour;
      position: absolute;
      left:0;
      top:50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      z-index: 501;
    }


    &:after{
      left:auto;
      right:0;
    }

  }


  p{
   
      @include ms-respond(font-size, 1);
      text-transform: uppercase;
      text-align: center;
      line-height: 2;
      width:100%;
      max-width: toRem(465px);
      margin-left:auto;
      margin-right:auto;
      position: relative;

  }


}



.location{

  @include breakpoint(639px down) {
     //margin-top: toRem(60px);
    }

  h3{
    width:100%;
    max-width: toRem(262px);

     &:after{
        margin: toRem(20px) 0 toRem(30px) 0;
        display: block;
        width: toRem(40px);
        height:toRem(2px);
        background:$signatureTwo;
        content: '';
    }

  }

  .medium-5{

    p{
      width:100%;
      max-width: toRem(365px);
    }

  }

  .row{
    width: 100%;
    margin: 0;

    .column{
      padding-left: toRem(6px);
      padding-right:0;
      margin-bottom: toRem(6px);
      position: relative;
      overflow: hidden;

      img{
        position: relative;
        z-index: 500;
        width:100%;
      }

      .overlay{
        display: block;
        position: absolute;
        left:toRem(6px);
        top:0;
        width:100%;
        height:100%;
        background:$signatureTwoAlpha;
        z-index: 501;
        opacity: 0;
        transition: all 0.25s ease-out;

        h4{
          @include ms-respond(font-size, 1);
        }




        
          span{
            padding: toRem(30px);
            position: absolute;
            display: block;
            width:100%;

              top:50%;
              left:50%;
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              -o-transform: translate(-50%, -50%);


          }

      }


      &:hover{
        .overlay{
          opacity: 1;
        }
      }

    }

  }


  #map{
    display: block;
    height:550px;
    width:100%;
    margin: 0 0 0 0;
  }


}



  #map{
    display: block;
    height:650px;
    width:100%;
    margin: toRem(25px) 0 toRem(25px) 0;
  }




.location.fullwidth{

  h3{
    max-width: toRem(400px);
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    &:after{
        margin: toRem(20px) auto toRem(30px) auto;
    }

  }

  p{
    max-width: toRem(660px);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: toRem(18px);
  }

  .overlay{
        
          span{
            padding: toRem(30px);
            position: absolute;
            display: block;
            width:100%;
            top:auto !important;
            left:auto !important;
            bottom:0 !important;
            transform: translate(0, 0) !important;
            -webkit-transform: translate(0, 0) !important;
            -ms-transform: translate(0, 0) !important;
            -o-transform: translate(0, 0) !important;

            p{
              text-align: left;
              margin-left: 0;
              margin-right: 0;
            }

          }

      }


}



.location.withmap{
  p{
    max-width: toRem(890px);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}



.well-connected{

  h3{
    @include ms-respond(font-size, 5);
  }

  .connected-section{

    p{
      display: block;
      width:100%;
      max-width: toRem(565px);
      font-size: toRem(17px);
    }

    .disclaimer{
      color:#afa198;
      font-size:toRem(14px);
      letter-spacing: toRem(0.3px);
      margin-bottom: 0;
      margin-top: toRem(20px);
    }

    ul.tabs{

      margin:toRem(35px) 0 toRem(20px) 0;

      li{

        padding:0;
        margin-right: toRem(40px);


        @include breakpoint(550px down) {
          margin-right: toRem(20px);
        }

        a{
          font-size: toRem(26px);
          color:$grey;
          letter-spacing: toRem(2px);

          @include breakpoint(550px down) {
            font-size: toRem(20px);
          }

          &:hover{
            color: $signatureTwo;
          }

        }

        &:before{
          display: none;
        }

        &:after{
          display: block;
          content:".";
          position: absolute;
          bottom:0;
          right:toRem(-22px);
          font-size: toRem(26px);
          line-height: 1;
          color:$grey;

          @include breakpoint(550px down) {
            right:toRem(-11px);
            font-size: toRem(20px);
          }

        }

      }


      li.is-active{
        a{
          color: $signatureTwo;
        }
      }



      //*******************


      li:nth-child(3n+1){

        a{
          &:hover{
            color: $signatureOne;
          }
        }

      }


      li:nth-child(3n+1).is-active{
        a{
          color: $signatureOne;
        }
      }


      //*******************



      li:nth-child(3n+2){

        a{
          &:hover{
            color: $signatureTwo;
          }
        }

      }


      li:nth-child(3n+2).is-active{
        a{
          color: $signatureTwo;
        }
      }


      //*******************


      li:nth-child(3n+3){

        a{
          &:hover{
            color: $signatureTwo;
          }
        }

      }


      li:nth-child(3n+3).is-active{
        a{
          color: $signatureTwo;
        }
      }

      //*******************


      li:last-of-type{

        &:after{
          display: none;
        }

      }



    }


    .tabs-content{

      ul{
        display: inline-block;
        width: 100%;
        //max-width: toRem(955px);
        position: relative;
        z-index: 550;
        overflow: hidden;
        margin-left: 0;
        font-size: 0;

        &:before {
          content: "";
          display: block;
          vertical-align: middle;
          margin: 0 0;
          position: absolute;
          z-index: 450;
          background: $signatureTwo;

          @include breakpoint(501px up) {
          width: 80%;
          height: toRem(10px);
          top:40%;
          left:10%;
          background-attachment: fixed !important;
          -webkit-transform: translateY(-60%);
              -ms-transform: translateY(-60%);
                  transform: translateY(-60%);
               -o-transform: translateY(-60%);
        }
          

          @include breakpoint(500px down) {

          width: toRem(10px);
          height: 80%;
          top:10%;
          left:50%;
          background-attachment: fixed !important;
          -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
                  transform: translateX(-50%);
               -o-transform: translateX(-50%);

          }

        }

        li{
          margin:0 4% 0 0;
          padding: toRem(30px) 0;
          display: inline-block;
          line-height: 1;
          text-align: center;
          position: relative;
          z-index: 660;
          vertical-align: top;
          width:16%;

          letter-spacing: toRem(1px);

          @include breakpoint(500px down) {
            width:100%;
            margin: 0;
            padding:toRem(10px) 0 toRem(20px) 0;
          }

          strong.place{
            display: inline-block;
            font-size: toRem(11px);
            width:100%;
            max-width: 70%;
            margin-left: auto;
            margin-right: auto;
            line-height: 1.3;
            text-transform: none;
            height:toRem(30px);
            text-transform: uppercase;
                    letter-spacing: toRem(2px);

            @include breakpoint(750px down) {
              max-width: 100%;
              height:auto;
            }


          }

          &:before{
            display: none;
          }


          span{
            display: block;
            width:toRem(115px);
            height: toRem(115px);
            border:solid toRem(10px) #e8e8e8;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            text-align: center;
            font-size: toRem(17px);
            margin:0 auto;
            padding:0 0;
            background: #fff;
            color: #e8e8e8;
            margin-bottom: toRem(20px);
            position: relative;
            vertical-align: top;

            background: #f7f5f2 url(../img/bg.jpg) repeat left top;


            @include breakpoint(750px down) {
              width:toRem(90px);
              height: toRem(90px);
              margin-bottom: toRem(5px);
            }


            strong{
              width:100%;
              margin-right: auto;
              margin-left: auto;
              display: block;
              font-size: toRem(17px);
              font-weight: 400 !important;
              line-height: 1;
              position: absolute;
              top:50%;
              text-align: center;
               -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
                  transform: translateY(-50%);
               -o-transform: translateY(-50%);
               height:auto;
               background:transparent;
               color:$textColour;
               text-transform: none !important;

              @include breakpoint(750px down) {
                  font-size: toRem(15px);
              }

               b{
                font-weight: 400;
                display: block;
                font-size: toRem(30px);

                @include breakpoint(750px down) {
                  font-size: toRem(20px);
                }

               }

            }

          }


        }


        li:last-of-type{
          margin-right: 0;
        }


      }

    }





     //*******************


      .tabs-panel:nth-child(3n+1){

        li{
          span{
            border-color:$signatureOne;
          }
        }

      }


      //*******************



      .tabs-panel:nth-child(3n+2){

        li{
          span{
            border-color:$signatureTwo;
          }
        }

      }




      //*******************


      .tabs-panel:nth-child(3n+3){

        li{
          span{
            border-color:$signatureTwo;
          }
        }

      }


      //*******************


  }


}




.three-col{

  @include breakpoint(639px down) {
     //margin-top: toRem(60px);
    }

  h5{
    font-size: toRem(16px);
  }

  img{
    display: block;
    width: 100%;
    margin: 0 auto toRem(20px) auto;
    max-width: toRem(340px); 

    @include breakpoint(medium up) {
       margin: 0 0 toRem(20px) 0;
    }

  }

  p{
    width:100%;
    font-size: toRem(16px);
    margin: 0 0 toRem(30px) 0;

    line-height:1.7;

    @include breakpoint(medium up) {
      max-width: toRem(340px);  
      margin: 0 0 toRem(20px) 0;
    }

  }

}



.housing-choice{

  @include breakpoint(639px down) {
     //margin-top: toRem(60px);
    }

  overflow: hidden;

  p{
    max-width: toRem(850px);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: toRem(20px);
  }

  .row{
    margin-right: 0;
    margin-left: 0;
    margin-top: toRem(30px);

    .column{
      padding-right: 10%;
      padding-left: 0;
      padding-bottom: toRem(30px);
      @include border(right, toRem(2px), solid, $signatureTwo);
      @include border(bottom, toRem(2px), solid, $signatureTwo);

      @include breakpoint(639px down) {
        border-right:none !important;
        padding-right:0 !important; 
      }

      p{
        text-align: left;
        margin:0;
        font-size: toRem(16px);
      }

      img{
        margin-bottom: toRem(30px);
      }

      h5{
        font-size: toRem(26px);
        margin-top: toRem(30px);
        text-transform: none;
            letter-spacing: 0.001rem !important;
      }

    }

    .column:nth-child(2n){
      @include border(right, toRem(2px), solid, $backgroundColor);
      padding-left: 10%;
      padding-right: 0;

      @include breakpoint(639px down) {
        padding-left: 0;
        padding-top: toRem(60px);
      }

    }

    .column:nth-child(n+3){
      padding-top: toRem(60px);
      padding-bottom: 0;
      @include border(bottom, toRem(2px), solid, $backgroundColor);

      @include breakpoint(639px down) {
        padding-bottom: toRem(30px);
        @include border(bottom, toRem(2px), solid, $signatureTwo);
      }

    }


  }


}



.faq{
  overflow: hidden;

  @include breakpoint(639px down) {
     //margin-top: toRem(60px);
    }

  .accordion{

    background: transparent;

    li{
      padding-left: 0;
      margin-bottom: 0;
      @include border(bottom, toRem(1px), solid, $lightGrey);
      &:before{
        display: none;
      }
    }


    .accordion-title {
      position: relative;
      display: block;
      padding: toRem(18px) 0;
      border: none;
      
      font-size:toRem(16px);
      line-height: 1;
      color: $textColour;

      &:before{
        color: $signatureTwo;
        font-size: toRem(20px);
        font-weight: 400;
      }

      &:hover, &:focus {
        background-color: transparent;
      }

    }


    .accordion-content {
      padding: toRem(15px) 0 toRem(15px) 0;
      border: none;
      background-color: transparent;
      color: $signatureOneAlpha;

        font-size: toRem(14px) !important;
        color: $signatureOneAlpha;
        font-weight: 400 !important;
        line-height: 1.7;


    

  }



  }

}




.downloads{

  
  .large-12{
    overflow: hidden;
    position: relative;
    padding-bottom: toRem(45px);
  }

  h3, p{
    text-align: center;
    width:100%;
    max-width: toRem(550px);
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }



  p{
    font-size: 18px;
  }


  .row{
    margin-top: toRem(45px);
    text-align: center;
    width:100%;
    max-width: toRem(575px) !important;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .download-item{



      img{
        margin-bottom: toRem(20px);
      }

      a{
        margin-left: auto;
        margin-right: auto;
        width:100%;
        display: block;
      }

      .button{
        max-width: 150px;
        margin:0 auto;
      }

    }


  .download-item:last-of-type{

    padding-top: toRem(30px);
    
    @include breakpoint(640px up) {
      padding-top: 0;
    }

  }


  .download-item.add-border:last-of-type{

    padding-top: toRem(30px);
    
    @include breakpoint(640px up) {
      padding-top: 0;
        @include border(left, toRem(2px), solid, $signatureTwo);
    }

  }



  }



}



.signup{

  &:after{
    display: none !important;
  }

  h3{
    @include ms-respond(font-size, 5);
    text-align: center;
    width:100%;
    background:url("../img/border.png") repeat-x center center;
    min-height:toRem(48px);
    line-height: toRem(48px);
    margin-top: toRem(50px);

    span{
      display: inline-block;
      margin-left:auto;
      margin-right: auto;
      width:auto;
      min-height:toRem(48px);
      padding:0 toRem(20px);
      background:$backgroundColor;
    }

  }


  h4{
      @include ms-respond(font-size, 0);
      text-align: center;
      width:100%;
      margin-bottom: toRem(10px);
      margin-top: toRem(30px);

  }

  h4 + p{
    font-size: toRem(16px);
      text-align: center;
      width:100%;
      margin-bottom: toRem(20px) !important;

      &:after{
        position: relative;
        display: none;
        margin: toRem(30px) auto;
        width: toRem(40px);
        height:toRem(6px);
        background:$signatureTwo;
        content: '';
      }

  }



  p{
      text-align: center;
      width:100%;
      max-width: toRem(660px);
      margin-left: auto;
      margin-right: auto;

      a{
        color: $textColour;
      }

    }

    .button{
      margin:toRem(50px) auto toRem(30px) auto;
      width:100%;
      max-width: toRem(265px);
      display: block;
      background: $signatureTwo;
      border:none;

      &:hover{
        background: #242f3a;
      }
    }


}




.standard-text{

  @include breakpoint(639px down) {
     //margin-top: toRem(60px);
  }

  p, li{
    font-size:toRem(14px);
    line-height: 1.6;
    letter-spacing: 0 !important;
  }

}






.contentsection{

  &:after{
    display: block;
     @include border(bottom, toRem(2px), solid, $backgroundColor);
    content: '';
    width:98%;
    max-width: toRem(1200px);
    height:toRem(1px);
    padding:toRem(65px) 0 0 0;
    margin:0 auto toRem(10px) auto;
    clear:both;

    @include breakpoint(1024 up) {
      width:100%;
      margin:0 auto toRem(65px) auto;
    }

  }

}


.sameblock{

  &:after{
    display: block;
    @include border(bottom, toRem(2px), solid, $backgroundColor);
    content: '';
    width:98%;
    max-width: toRem(1200px);
    height:toRem(1px);
    padding:toRem(1px) 0 0 0;
    margin:0 auto toRem(10px) auto;
    clear:both;

    @include breakpoint(1024 up) {
      width:100%;
      margin:0 auto toRem(65px) auto;
    }

  }

}


.divider{

  &:after{
     @include border(bottom, toRem(2px), solid, $signatureTwo);
  }

}



#reserve, #register{

  .contentsection:last-of-type{

    &:after{
      padding:toRem(1px) 0 0 0;
      margin:0 auto toRem(1px) auto;

      @include breakpoint(1024 up) {
        margin:0 auto toRem(1px) auto;
      }

    }

  }

}



#register, #reserve{

  .text-only{
    p{
      text-align: center;
      color:#afa198;
      font-size:toRem(12px);
      letter-spacing: toRem(0.3px);
      margin-bottom: 0;
      margin-top: toRem(20px);
    }
  }

}



// Register Form **********************************************


.reveal-overlay {
    z-index: 99999;
    display: none ;
    background-color: $primaryColour;
       width:100vw !important;
    height:100vh !important;

    @include breakpoint(639px down) {
        width:101vw !important;
        left: -3px !important;
    }

    .close-button{
      position: absolute;
      top: 1%;
      right: 2%;
      z-index: 9999999;
      font-size: toRem(90px);
      font-weight: 100 !important;

      font-family:"Times New Roman", serif !important;


      @include breakpoint(small only) {
        font-size: toRem(80px);
        top: toRem(-15px);
        right:auto;
        left: 50%;
        transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
      }

    }

}

.reveal {
    z-index: 999991;
    padding: 0;
    border: none;
    border-radius: 0;
    width:100%;
    max-width: 100%;
    background: transparent;
    color:$backgroundColor;
    top:0 !important;

    .revealWrap{
      max-width: 800px;
      margin:0 auto;
      overflow: hidden;
    }

    .medium-6{
      position: relative;
      
      @include breakpoint(640px up) {
        height:toRem(325px);
        margin-bottom: toRem(30px);
      }

    }


    .registerlogo{

      @include breakpoint(639px down) {
        padding:0 !important;
      }

    }




    .registerInfo{
      
      @include breakpoint(640px up) {
        position: absolute;
        bottom:0;
        left:5%;
        width:90%;

        p{
          margin-bottom: 0;
        }

      }

        
    }



      @include breakpoint(small only) {
        padding-bottom:toRem(45px);
      }


    h4{
      position: relative;

      font-size: toRem(22px);
      


    }

    p{
      font-size: toRem(18px);
    }

    form{

       .medium-6{
        height:auto;
        margin-bottom: 0;
      }

      .input-group{
        display: block !important;
        font-size: toRem(12px);
      }

      ul{

        width:80%;
        float:left;
        clear:none;

        li{
          padding-left: 0 !important;
          font-size: toRem(12px) !important;
          margin-top: toRem(5px) !important;
          margin-bottom: 0 !important;
          overflow: hidden;

          label{
            font-size: toRem(12px) !important;
            line-height: toRem(12px) !important;
            text-transform: none;
            font-weight: 400 !important;
            float:left;
            clear:none;
            margin:0 !important;
            width:90%;
            letter-spacing:initial;
          }

          input{
            margin-bottom: 0 !important;
            line-height: toRem(12px) !important;
            height:toRem(18px) !important;
            font-size: toRem(12px);
            float:left;
            clear:none;
            width:auto;
            text-align: left;
            margin-right: 2.5%;
          }


          &:before{
            display: none;
          }
        }
      }


      label{
        color:$backgroundColor;
        font-size: toRem(14px);
        text-transform: uppercase;
        letter-spacing: toRem(2px);
      }

      input:not(.button){
        border:none;
        @include border(bottom, toRem(3px), solid, $backgroundColor);
        background:$primaryColour !important;
        color:$backgroundColor;
        font-size: toRem(14px);
        height:toRem(35px);
        line-height:toRem(35px);
        margin-bottom: toRem(35px);
        box-shadow: none !important;
        padding-left: 0;
        letter-spacing: toRem(2px);
      }

      textarea{
        @include fullborder(toRem(4px), solid, $backgroundColor);
        background:$backgroundColor !important;
        color:$signatureOne;
        font-size: toRem(14px);
        height:toRem(150px);
        box-shadow: none !important;
        margin-bottom: 2.125rem;
      }

      .button{
        float:right;
        clear:none;
        background: $signatureTwo;
        border:none !important;
        font-size: toRem(13px);
        padding-left:toRem(30px);
        padding-right:toRem(30px);

        &:hover{
          background: $backgroundColor;
          color: $textColour;
        }

      }

    input.mce_inline_error::-webkit-input-placeholder  {
        color: $backgroundColor !important;
        text-transform: uppercase;
    }

    input::-webkit-input-placeholder {
      color: $backgroundColor !important;
      text-transform: uppercase;
    }
    input::-moz-placeholder {
       color: $backgroundColor !important;
       text-transform: uppercase;
    }
    input::-ms-input-placeholder {
        color: $backgroundColor !important;
        text-transform: uppercase;
    }
    input::-moz-placeholder {
        color: $backgroundColor !important;
        text-transform: uppercase;
    }


    label.error {display:block; float:none; width:auto; margin-left:0; text-align:left; padding:.5em 0;}
    div.mce_inline_error{ display: none !important;  }

    div.mce_inline_error:first-of-type{
      display: block !important;
      color:#e85c41 !important;
    }

    input.mce_inline_error {border-color: #e85c41 !important; color:#e85c41 !important;}


    }


}



 footer{
  background:$signatureOne;
  padding: toRem(45px) 0;
  margin-top: toRem(45px);

  color:$backgroundColor;



  .large-4{
    @include breakpoint(1023px down) {
      margin-top: toRem(60px);
      text-align: center;
    }
  }

  .first{
  
    @include breakpoint(640px up) {
      @include border(right, toRem(2px), solid, $backgroundColor);
    }

  }

  .columns{
    position: relative;
  }

  h5{
    font-size: toRem(10px);
    position: absolute;
    top:0;
  }

  p{
      font-size: toRem(12px);
      font-weight: 400 !important;

        a{
        border:none !important;
        color: $backgroundColor !important;
        text-transform: none;
        font-size: toRem(12px);
        font-weight: 400 !important;
        } 
  }

  p.legal{
  max-width: 100%;
  margin-top: 0;
 }


 p:last-of-type{
  margin-bottom: 0;
 }


 ul{
  list-style-type: none;
  display: inline-block;
  margin: 0;
  width:100%;
  height:auto;

  


  li{
    display: block;
    float:left;
    clear:none;
    margin:0;
    padding:0;
    width:20%;
    margin-right: 5%;
    text-align: left;
    position: relative;
    
    height:68px !important;

    a{
      display: block;
      width:100%;
      position: absolute;
      bottom: 0;
      
    }

    img{
      display: block;
      margin:0;
      position: absolute;
      bottom: toRem(-5px);
    }


  }

  li.tall{
    img{
    }
  }

  li:before {
    display: none;
  }


 }


 ul.development-by{
  li{
    width:45%;
    margin-right: 5%;
    text-align: left;
  }
 }



 }



.breakout{
h2{
    //margin-top: toRem(50px);
    margin-bottom: toRem(30px);
  }

  &:after{
    display: none !important;
  }


}


  

//Fonts Loaded *************************************************



.fonts-loaded, .wf-active{





	body{
		@extend .body-font-light;
	}

	p strong, p a{
		@extend .body-font-medium;
	}

  nav{
    a{
      @extend .body-font-bold;
    }
  }


  // h1, h2, h3, h4, h5, h6
  @each $size, $headers in $header-sizes {
    @each $header, $font-size in $headers {
      #{$header} {

        @extend .header-font;

        & + p{
          @extend .body-font-medium;
        }

      }
    }
  }



  .button, .centred-quote p, .text-image h3{
    @extend .header-font;
  }

  .centred-image-text, .centred-image-two-cols, .text-only, .location, .downloads, .signup, .three-col, .connected-section, .text-image-list{
    @extend .body-font-medium;
    p{
      @extend .body-font-medium;
    }
  }


  h2, h3, .centred-image-two-cols h4, .breakout-text-list p, .breakout-text-button .large-6:last-of-type p, .feature .text-image h3, .housing-choice .sectiontitle p, .housing-choice .column h5{
    @extend .subheader-font;
  }


  #housetypes{
    h4{
      @extend .body-font-medium;
    }
  }

  .hero-content{

    h3{
      @extend .header-font;
    }

    h4{
      @extend .subheader-font;
    }

  }


  .slick-slide{
    figcaption{
      p{
        @extend .body-font-medium;
      }
    }
  }


  ul{
    li, strong.place{
      @extend .header-font;
    }
  }



.text-image{

  p{
    @extend .body-font-medium;
  }

}



form{
  @extend .header-font;
      label, input, textarea{
        @extend .header-font;
      }
    }




.breakout-text-list{

    ul{
      li{
        @extend .body-font-book;
        p{
          @extend .body-font-book;
        }
      }
    }

  }


.accordion, .standard-text{

  @extend .body-font-book;

  li{
      @extend .body-font-book;
    p{
        @extend .body-font-book;
    }
  }
}





}





.fonts-loading, .wf-loading{

	body{
		opacity: 0;

		//transform: translateY(-10vh);
		//-webkit-transform: translateY(-10vh);
		//-ms-transform: translateY(-10vh);
	}

}



